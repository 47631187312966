import React from "react";
import ReactMarkdown from "react-markdown";
import Breaks from "remark-breaks";
import styled from "styled-components";

const StyledReactMarkdown = styled(ReactMarkdown)`
  ol,
  ul {
    padding: 16px 0 0 16px;
  }
`;

const Markdown = (props) => {
  return (
    <StyledReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[Breaks]}
      components={{ pre: "div", code: "p" }}
    >
      {props.children}
    </StyledReactMarkdown>
  );
};

export default Markdown;
