import React, { useState, useEffect, useRef } from "react";
import { useSubscription } from "@apollo/client";
import { FormattedMessage, injectIntl } from "react-intl";
import { Radio, Grid, Space } from "antd";
import styled from "styled-components";
import { StyledTable } from "./index";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import { useAccess } from "../../UserAccessContext";
import {
  dateFormat,
  parseTickets,
  getCountFromTickets,
  getPageFromOffset,
  getOffsetFromPage,
} from "../../tableMethods";
import {
  TICKETS_WITH_COMMENTS_SUBSCRIPTION,
  MY_TICKETS_WITH_COMMENTS_COUNT,
} from "../../queries";
import Markdown from "../../Components/Markdown";
import TicketComments from "./TicketComments";
import { userFeedbackNotification } from "../../Components/userFeedbackNotification";
import CustomEmpty from "../../Components/CustomEmpty";
import {
  ExpandedInfo,
  ExpandedInfoHeaders,
  ExpandedInfoId,
  TicketContent,
  HeaderContainer,
} from "./OpenTicketsTable";

const { useBreakpoint } = Grid;

export const StyledRadio = styled(Radio.Group)`
  display: flex;
  margin-bottom: 10px;

  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 575px) {
    justify-content: flex-start;
  }
`;

const ClosedTicketTable = (props) => {
  const { viewAll } = props;
  const { user } = useAuth0();
  const { isAdmin } = useAccess();
  const screens = useBreakpoint();
  const adminViewingAll = isAdmin && viewAll;
  const queryEmail = adminViewingAll ? null : user.email;
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [sortDirection, setSortDirection] = useState("descend");
  const [sortField, setSortField] = useState("created_at");
  const [filterDate, setFilterDate] = useState(null);

  const tableData = useRef();

  let orderBy = {};
  orderBy[sortField] = sortDirection === "ascend" ? "asc" : "desc";

  if (
    sortField === "ticket_to_user_by_userid.name" ||
    sortField === "requester"
  ) {
    orderBy = {
      ticket_to_user_by_userid: {
        name: sortDirection === "ascend" ? "asc" : "desc",
      },
    };
  }

  const {
    data: countData,
    loading: countLoading,
    error: countError,
  } = useSubscription(MY_TICKETS_WITH_COMMENTS_COUNT, {
    variables: {
      allowedStatuses: ["closed", "solved"],
      email: queryEmail,
      fromDate: filterDate,
    },
    skip: !user || !user.email,
  });
  const { data, loading, ticketsError } = useSubscription(
    TICKETS_WITH_COMMENTS_SUBSCRIPTION,
    {
      variables: {
        orderBy,
        allowedStatuses: ["closed", "solved"],
        email: queryEmail,
        limit: 10,
        offset: paginationOffset,
        fromDate: filterDate,
      },
      skip: !user || !user.email,
    }
  );

  const closedTickets = parseTickets(data);
  const ticketCount = getCountFromTickets(countData);

  if (!loading || tableData.current === undefined) {
    tableData.current = closedTickets;
  }

  useEffect(() => {
    if (ticketsError || countError) {
      userFeedbackNotification(
        props.intl.formatMessage({ id: "Tickets.fetchFailedError" }),
        "error"
      );
    }
    // eslint-disable-next-line
  }, [ticketsError, countError]);

  const closedColumns = [
    {
      title: <FormattedMessage id="Tickets.id" />,
      dataIndex: "id",
      width: "70px",
      sorter: true,
      sortOrder: sortField === "id" ? sortDirection : false,
      responsive: ["md", "lg", "xl", "xxl"],
    },
    {
      title: <FormattedMessage id="Tickets.subject" />,
      dataIndex: "subject",
      sorter: true,
      sortOrder: sortField === "subject" ? sortDirection : false,
    },
    {
      title: <FormattedMessage id="Tickets.date.created" />,
      dataIndex: "created_at",
      sorter: true,
      sortOrder: sortField === "created_at" ? sortDirection : false,
      defaultSortOrder: "descend",
      render: dateFormat,
      width: "180px",
      responsive: adminViewingAll
        ? ["lg", "xl", "xxl"]
        : ["md", "lg", "xl", "xxl"],
    },
    {
      title: <FormattedMessage id="Tickets.date.solved" />,
      dataIndex: "updated_at",
      sorter: true,
      sortOrder: sortField === "updated_at" ? sortDirection : false,
      render: dateFormat,
      width: (screens.xs || screens.sm) && !screens.md ? "160px" : "180px",
      responsive: adminViewingAll
        ? ["lg", "xl", "xxl"]
        : ["xs", "sm", "md", "lg", "xl", "xxl"],
    },
  ];

  if (adminViewingAll) {
    closedColumns.push({
      title: <FormattedMessage id="Tickets.requester" />,
      dataIndex: "requester",
      sorter: true,
      sortOrder: sortField === "requester" ? sortDirection : false,
      width: "230px",
      key: "closedTickets",
    });
  }

  function filterTickets(e) {
    e.preventDefault();
    let dateNow = dayjs();
    let filterDate = null;

    switch (e.target.value) {
      case "three_months":
        filterDate = dateNow.subtract(3, "M");
        break;
      case "six_months":
        filterDate = dateNow.subtract(6, "M");
        break;
      case "year":
        filterDate = dateNow.subtract(1, "y");
        break;
      default:
        filterDate = null;
    }

    setFilterDate(filterDate);
    setPaginationOffset(0);
  }

  const onChange = (pagination, filters, sorter) => {
    setPaginationOffset(getOffsetFromPage(pagination.current));
    setSortDirection(sorter.order ? sorter.order : "ascend");
    setSortField(sorter.field);
  };

  const FilterWrapper = ({ children }) => {
    // Show filter options vertically on small screens.
    if (screens.xs && !screens.md) {
      return (
        <Space direction="vertical" align="left">
          ${children}
        </Space>
      );
    }
    return <div>${children}</div>;
  };

  return (
    <>
      <HeaderContainer $columnsFromWidth={768}>
        <h2 style={{ marginBottom: "10px" }}>
          <FormattedMessage id="Tickets.closed" />
        </h2>
        <StyledRadio defaultValue="all" onChange={filterTickets}>
          <FilterWrapper>
            <Radio.Button value="three_months">
              <FormattedMessage id="Tickets.date.solved_filter.three_month" />
            </Radio.Button>
            <Radio.Button value="six_months">
              <FormattedMessage id="Tickets.date.solved_filter.six_month" />
            </Radio.Button>
            <Radio.Button value="year">
              <FormattedMessage id="Tickets.date.solved_filter.year" />
            </Radio.Button>
            <Radio.Button value="all">
              <FormattedMessage id="Tickets.date.solved_filter.all" />
            </Radio.Button>
          </FilterWrapper>
        </StyledRadio>
      </HeaderContainer>
      <StyledTable
        loading={loading || countLoading}
        columns={closedColumns}
        dataSource={tableData.current}
        onChange={onChange}
        size="middle"
        locale={{
          emptyText: <CustomEmpty descriptionId="Tickets.closedEmptyText" />,
        }}
        expandedRowRender={(record) => (
          <>
            <ExpandedInfo $visibleFromWidth={adminViewingAll ? 992 : 768}>
              <ExpandedInfoId>
                <ExpandedInfoHeaders>
                  <FormattedMessage id="Tickets.id" />:
                </ExpandedInfoHeaders>{" "}
                {record.id}
              </ExpandedInfoId>
              {adminViewingAll || (!viewAll && !screens.md) ? (
                <div>
                  <ExpandedInfoHeaders>
                    <FormattedMessage id="Tickets.date.created" />:
                  </ExpandedInfoHeaders>{" "}
                  {dateFormat(record.created_at)}
                </div>
              ) : null}
              {adminViewingAll &&
                (screens.xs || screens.sm || screens.md || screens.lg) ? (
                <div>
                  <ExpandedInfoHeaders>
                    <FormattedMessage id="Tickets.date.solved" />:
                  </ExpandedInfoHeaders>{" "}
                  {dateFormat(record.updated_at)}
                </div>
              ) : null}
            </ExpandedInfo>
            <TicketContent>
              <Markdown>{record.description}</Markdown>
            </TicketContent>
            {record.comments.length > 1 ? (
              <>
                <TicketComments
                  comments={record.comments}
                  dateFormat={dateFormat}
                />
              </>
            ) : null}
          </>
        )}
        pagination={{
          pageSize: 10,
          hideOnSinglePage: true,
          total: ticketCount,
          current: getPageFromOffset(paginationOffset),
          showSizeChanger: false,
        }}
        rowKey="id"
        showExpandColumn={(!screens.xs || !screens.sm) && screens.md}
        expandRowByClick
      />
    </>
  );
};

export default injectIntl(ClosedTicketTable);
