import React, { useState, createContext, useContext } from "react";

import { languageOptions } from "./";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";

export const LanguageContext = createContext({
  language: languageOptions[0],
});

export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);

  const provider = {
    language,
    setLanguage: (selectedLanguage) => {
      if (selectedLanguage === "en") {
        setLanguage(languageOptions[1]);
      } else {
        setLanguage(languageOptions[0]);
      }
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      <IntlProvider
        messages={language.translation}
        locale={language.locale}
        key={language.locale}
      >
        <ConfigProvider locale={language.antDesign}>
          {props.children}
        </ConfigProvider>
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
