import React, { useState, useEffect, useRef } from "react";
import { useSubscription } from "@apollo/client";
import { FormattedMessage, injectIntl } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Popover, Card, Grid } from "antd";
import styled from "styled-components";

import { useAccess } from "../../UserAccessContext";

import {
  TICKETS_WITH_COMMENTS_SUBSCRIPTION,
  MY_TICKETS_WITH_COMMENTS_COUNT,
} from "../../queries";
import {
  dateFormat,
  parseTickets,
  getCountFromTickets,
  getPageFromOffset,
  getOffsetFromPage,
} from "../../tableMethods";
import { StyledTable } from "./index";
import Markdown from "../../Components/Markdown";
import NewComment from "./NewComment";
import TicketComments from "./TicketComments";
import Status from "./Status";
import { userFeedbackNotification } from "../../Components/userFeedbackNotification";
import CustomEmpty from "../../Components/CustomEmpty";

const { useBreakpoint } = Grid;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.$columnsFromWidth}px) {
    flex-direction: column;
  }
`;

export const ExpandedInfo = styled(Card)`
  margin-bottom: 10px;

  > .ant-card-body {
    padding: 16px;
  }

  @media (min-width: ${(props) => props.$visibleFromWidth}px) {
    display: none;
  }
`;

export const ExpandedInfoHeaders = styled.span`
  font-weight: 500;
`;

export const ExpandedInfoId = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const TicketContent = styled(Card)`
  overflow-wrap: anywhere;
  margin-bottom: 10px;

  > .ant-card-body {
    padding: 16px 16px 2px 16px;
  }
`;

const OpenTicketsTable = (props) => {
  const { user } = useAuth0();
  const { viewAll, adminToggle } = props;
  const { isAdmin } = useAccess();
  const screens = useBreakpoint();
  const adminViewingAll = isAdmin && viewAll;
  const queryEmail = adminViewingAll ? null : user.email;
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [sortDirection, setSortDirection] = useState("descend");
  const [sortField, setSortField] = useState("created_at");

  const tableData = useRef();

  let orderBy = {};
  orderBy[sortField] = sortDirection === "ascend" ? "asc" : "desc";

  if (sortField === "requester") {
    orderBy = {
      ticket_to_user_by_userid: {
        email: sortDirection === "ascend" ? "asc" : "desc",
      },
    };
  }

  const {
    data: countData,
    loading: countLoading,
    error: countError,
  } = useSubscription(MY_TICKETS_WITH_COMMENTS_COUNT, {
    variables: {
      allowedStatuses: ["new", "open", "hold", "pending", "cache"],
      email: queryEmail,
    },
    skip: !user || !user.email,
  });
  const { data, loading, ticketsError } = useSubscription(
    TICKETS_WITH_COMMENTS_SUBSCRIPTION,
    {
      variables: {
        orderBy,
        allowedStatuses: ["new", "open", "hold", "pending", "cache"],
        email: queryEmail,
        limit: 10,
        offset: paginationOffset,
      },
      skip: !user || !user.email,
    }
  );

  const openTickets = parseTickets(data);

  if (!loading || tableData.current === undefined) {
    tableData.current = openTickets;
  }

  const ticketCount = getCountFromTickets(countData);
  const statusPopoverList = ["new", "open", "hold", "pending"];
  const statusPopover = (
    <div>
      {statusPopoverList.map(function (name) {
        return (
          <div key={name}>
            <FormattedMessage id={`Tickets.filter.${name}`} /> -{" "}
            <FormattedMessage id={`Tickets.filter.${name}.description`} />{" "}
          </div>
        );
      })}
    </div>
  );

  useEffect(() => {
    if (ticketsError || countError) {
      userFeedbackNotification(
        props.intl.formatMessage({ id: "Tickets.fetchFailedError" }),
        "error"
      );
    }
    // eslint-disable-next-line
  }, [ticketsError, countError]);

  const openColumns = [
    {
      title: (
        <div>
          <FormattedMessage id="Tickets.id" />
        </div>
      ),
      dataIndex: "id",
      width: "70px",
      sorter: true,
      sortOrder: sortField === "id" ? sortDirection : false,
      defaultSortOrder: "ascend",
      responsive: ["md", "lg", "xl", "xxl"],
    },
    {
      title: (
        <div>
          <FormattedMessage id="Tickets.subject" />
        </div>
      ),
      dataIndex: "subject",
      sorter: true,
      sortOrder: sortField === "subject" ? sortDirection : false,
    },
    {
      title: (
        <div>
          <Popover
            placement="topRight"
            content={statusPopover}
            title={<FormattedMessage id="Tickets.status" />}
          >
            <InfoCircleTwoTone color="grey" style={{ marginRight: "5px" }} />
          </Popover>
          <FormattedMessage id="Tickets.status" />
        </div>
      ),
      dataIndex: "status",
      width: "130px",
      render: (status) => (
        <Status
          status={status}
          minWidth={76}
          text={<FormattedMessage id={`Tickets.filter.${status}`} />}
        />
      ),
      sorter: true,
      sortOrder: sortField === "status" ? sortDirection : false,
    },
    {
      title: <FormattedMessage id="Tickets.date.created" />,
      dataIndex: "created_at",
      sorter: true,
      sortOrder: sortField === "created_at" ? sortDirection : false,
      defaultSortOrder: "descend",
      render: dateFormat,
      width: "180px",
      responsive: adminViewingAll
        ? ["lg", "xl", "xxl"]
        : ["md", "lg", "xl", "xxl"],
    },
    {
      title: <FormattedMessage id="Tickets.date.updated" />,
      dataIndex: "updated_at",
      sorter: true,
      sortOrder: sortField === "updated_at" ? sortDirection : false,
      defaultSortOrder: "descend",
      render: dateFormat,
      width: "180px",
      responsive: ["lg", "xl", "xxl"],
    },
  ];

  if (adminViewingAll) {
    openColumns.push({
      title: <FormattedMessage id="Tickets.requester" />,
      dataIndex: "requester",
      sorter: true,
      sortOrder: sortField === "requester" ? sortDirection : false,
      width: "230px",
      key: "openTickets",
      render: (content) => (
        <span style={{ overflowWrap: "anywhere", minWidth: 100 }}>
          {content}
        </span>
      ),
    });
  }

  const onChange = (pagination, filters, sorter) => {
    setPaginationOffset(getOffsetFromPage(pagination.current));
    setSortDirection(sorter.order ? sorter.order : "ascend");
    setSortField(sorter.field);
  };

  return (
    <>
      <HeaderContainer $columnsFromWidth={575}>
        <h2 style={{ marginBottom: "10px" }}>
          <FormattedMessage id="Tickets.open" />
        </h2>
        {adminToggle}
      </HeaderContainer>
      <StyledTable
        loading={loading || countLoading}
        columns={openColumns}
        dataSource={tableData.current}
        onChange={onChange}
        size="middle"
        locale={{
          emptyText: <CustomEmpty descriptionId="Tickets.emptyText" />,
        }}
        expandedRowRender={(record) => (
          <>
            <ExpandedInfo $visibleFromWidth={992}>
              <ExpandedInfoId>
                <ExpandedInfoHeaders>
                  <FormattedMessage id="Tickets.id" />:
                </ExpandedInfoHeaders>{" "}
                {record.id}
              </ExpandedInfoId>
              {adminViewingAll || (!viewAll && !screens.md) ? (
                <div>
                  <ExpandedInfoHeaders>
                    <FormattedMessage id="Tickets.date.created" />:
                  </ExpandedInfoHeaders>{" "}
                  {dateFormat(record.created_at)}
                </div>
              ) : null}
              <div>
                <ExpandedInfoHeaders>
                  <FormattedMessage id="Tickets.date.updated" />:
                </ExpandedInfoHeaders>{" "}
                {dateFormat(record.updated_at)}
              </div>
            </ExpandedInfo>
            <TicketContent>
              <Markdown>{record.description}</Markdown>
            </TicketContent>
            {record.comments.length > 1 ? (
              <>
                <TicketComments
                  comments={record.comments}
                  dateFormat={dateFormat}
                />
              </>
            ) : null}
            <NewComment ticketId={record.id} />
          </>
        )}
        pagination={{
          pageSize: 10,
          hideOnSinglePage: true,
          total: ticketCount,
          current: getPageFromOffset(paginationOffset),
          showSizeChanger: false,
        }}
        rowKey="id"
        showExpandColumn={(!screens.xs || !screens.sm) && screens.md}
        expandRowByClick
      />
    </>
  );
};

export default injectIntl(OpenTicketsTable);
