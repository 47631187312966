import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Collapse } from "antd";
import styled from "styled-components";

import Markdown from "../../Components/Markdown";
import { commentDateSorter } from "../../tableMethods";

const { Panel } = Collapse;

const StyledCommentDate = styled.span`
  color: #777;
  margin-left: 15px;
`;

const DisruptionReplies = (props) => {
  const { replies, dateFormat } = props;
  const [visibleReplies, setVisibleReplies] = useState([replies.length - 1]);

  const handleCollapseChange = (visibleReplies) => {
    setVisibleReplies(visibleReplies);
  };

  const repliesList = replies
    .sort(commentDateSorter)
    .map((reply, index) => (
      <Panel
        header={
          <>
            <span>
              <FormattedMessage id="Disruptions.update" />:
            </span>
            <StyledCommentDate>{dateFormat(reply.created)}</StyledCommentDate>
          </>
        }
        key={index}
        forceRender={true}
      >
        <Markdown>{reply.body}</Markdown>
      </Panel>
    ))
    .slice(1); // Skip the first comment as it is rendered separately in OpenTicketsTable.

  return (
    <>
      <Collapse activeKey={visibleReplies} onChange={handleCollapseChange}>
        {repliesList}
      </Collapse>
    </>
  );
};

export default DisruptionReplies;
