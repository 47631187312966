import React from "react";
import { useSubscription } from "@apollo/client";
import { Alert, List, Row, Col } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TICKETS_CACHE_SUBSCRIPTION } from "../../queries";

const InfoIcon = styled(InfoCircleOutlined)`
  color: #1890ff;
  font-size: 24px;
  margin-right: 10px;
`;

const BlueListItem = styled(List.Item)`
  border-bottom: 1px solid #c6eaff !important;

  &:last-child {
    border: none !important;
  }
`;

const CachedTicketsAlert = (props) => {
  const { data, loading } = useSubscription(TICKETS_CACHE_SUBSCRIPTION);
  let alert = null;
  let tickets = [];

  try {
    tickets = data.sp_backend_ticket_cache;
  } catch {}

  if (!loading && data && tickets.length > 0) {
    const description = (
      <List
        size="small"
        bordered
        dataSource={tickets}
        renderItem={(item) => <BlueListItem>{item.subject}</BlueListItem>}
        style={{ border: "1px solid #c6eaff", marginTop: 14 }}
      />
    );

    alert = (
      <Row>
        <Col sm={24} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
          <Alert
            message={
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InfoIcon />
                  <FormattedMessage id="Tickets.tickets_being_registered" />
                </div>
              </>
            }
            description={description}
            type="info"
            style={{ marginBottom: 24 }}
          />
        </Col>
      </Row>
    );
  }

  return <>{alert}</>;
};

export default CachedTicketsAlert;
