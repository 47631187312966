import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

export const Wrapper = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Background = styled.div`
  // background-image: url("https://www.cag.se/wp-content/uploads/2020/01/home-start-systemutveckling-2.jpg");
  background-repeat: no-repeat;
  width: 698px;
  height: 576px;
  background-size: contain;

  @media (max-width: 767px) {
    background-image: none;
    display: none;
  }
`;

export const Text = styled.div`
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  flex-grow: 1;

  @media (max-width: 767px) {
    padding-top: 10vh;
    padding-bottom: 10vh;
    min-width: 170px;
    width: 100%;
  }
`;

export const Gradient = styled.div`
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 0) 15%
  );
  flex-direction: column;
  height: 100%;
`;

export const LoginButton = styled(Button)`
  background-color: #0B2962;
  color: white;
  border-color: #0B2962;
  align-self: center;
  width: 100px;
  margin-top: 10%;
  &:hover {
    color: #0B2962;
    background-color: white;
    border-color: #0B2962;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
  }
`;

export const Welcome = styled.h1`
  font-size: 2.55em;
  font-weight: 200;
  letter-spacing: 0.0625em;
  text-align: center;
  max-width: 70%;
  @media (max-width: 575px) {
    font-size: 26px;
  }
`;

const LoadingMessage = styled.h1`
  font-weight: 200;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  margin-top: 10%;
`;

const LandingpageContent = () => {
  const { loginWithRedirect, isLoading: authLoading, error } = useAuth0();
  let content = null;

  if (authLoading) {
    content = (
      <LoadingMessage>
        <LoadingOutlined spin /> <FormattedMessage id="Home.signingInMessage" />
      </LoadingMessage>
    );
  } else if (error) {
    content = <ErrorMessage>{error.error_description}</ErrorMessage>;
  } else {
    content = (
      <LoginButton size="large" onClick={loginWithRedirect}>
        <FormattedMessage id="NavBar.login" />
      </LoginButton>
    );
  }

  return (
    <Wrapper>
      <Text>
        <Welcome>
          <FormattedMessage id="Home.welcome" />
        </Welcome>
        {content}
      </Text>
      <Background>
        <Gradient />
      </Background>
    </Wrapper>
  );
};

export default LandingpageContent;
