import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { useCustomFieldOptions } from "./NewTicketForm";

const { Option } = Select;

const OrderFormReports = (props) => {
  const { ticketType, ticketCategory } = props;
  const reportOptions = useCustomFieldOptions(
    ticketType,
    ticketCategory,
    "reports"
  );

  return reportOptions.length > 0 ? (
    <Form.Item
      name="report"
      label={<FormattedMessage id="NewTicketForm.reports" />}
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage id="NewTicketForm.reports_required_message" />
          ),
        },
      ]}
    >
      <Select mode="multiple" showArrow>
        {reportOptions.map((option, index) => {
          return (
            <Option key={index} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  ) : null;
};

export default OrderFormReports;
