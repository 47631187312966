import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import styled from "styled-components";

import Markdown from "../../Components/Markdown";
import { getUserFieldFromComment, commentDateSorter } from "../../tableMethods";

const { Panel } = Collapse;

const StyledCommentDate = styled.span`
  color: #777;
  margin-left: 15px;
`;

const TicketComments = (props) => {
  const { comments, dateFormat } = props;
  const [allComments, setAllComments] = useState(
    comments.map((comment, index) => index)
  );
  const [visibleComments, setVisibleComments] = useState([comments.length - 1]);

  const expandNewTickets = () => {
    const newList = comments.map((comment, index) => index);

    newList.forEach((key) => {
      if (allComments.indexOf(key) === -1) {
        setVisibleComments(visibleComments.concat([key]));
        setAllComments(allComments.concat([key]));
      }
    });
  };
  // eslint-disable-next-line
  useEffect(expandNewTickets, [comments]);

  const handleCollapseChange = (visibleComments) => {
    setVisibleComments(visibleComments);
  };

  const commentsList = comments
    .sort(commentDateSorter)
    .map((comment, index) => (
      <Panel
        header={
          <>
            <span>{getUserFieldFromComment(comment, "name")}</span>
            <StyledCommentDate>{dateFormat(comment.created)}</StyledCommentDate>
          </>
        }
        key={index}
        forceRender={true}
        style={{ overflowWrap: "anywhere" }}
      >
        <Markdown>{comment.body}</Markdown>
      </Panel>
    ))
    .slice(1); // Skip the first comment as it is rendered separately in OpenTicketsTable/ClosedTicketTable.

  return (
    <>
      <Collapse activeKey={visibleComments} onChange={handleCollapseChange}>
        {commentsList}
      </Collapse>
    </>
  );
};

export default TicketComments;
