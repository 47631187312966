import React from "react";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";

export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block0",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          className: "logo",
          children: <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="CAG" width="160" />,
        },
        childWrapper: {
          className: "slogan",
          children: [],
        },
      },
      {
        name: "block1",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: null /*<FormattedMessage id="Footer.whatwedo" />*/ },
        childWrapper: {
          children: [],
        },
      },
      {
        name: "block2",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: <FormattedMessage id="Footer.links" /> },
        childWrapper: {
          children: [
            {
              href: "https://www.cag.se/",
              name: "link1",
              children: <FormattedMessage id="Footer.website" />,
            },
            {
              href: "https://www.cag.se/integritetspolicy/",
              name: "link0",
              children: <FormattedMessage id="Footer.privacy" />,
            },
            {
              href: "https://www.cag.se/pressmeddelanden/c-a-g-datastod-certifieras-enligt-iso-27001/",
              name: "link1",
              children: <FormattedMessage id="Footer.ISO" />,
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        ©{dayjs().year()} by <a href="https://cag.se">CAG Datastöd</a> All Rights Reserved
      </span>
    ),
  },
};
