import React from "react";
import { Form, Input, Button } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const { TextArea } = Input;

export const SubmitButton = styled(Button)`
  background-color: #0B2962;
  color: white;
  border-color: #0B2962;
  &:hover {
    color: #0B2962;
    background-color: white;
    border-color: #0B2962;
  }
`;

const NewCommentForm = (props) => {
  const { form, loading } = props;

  return (
    <Form
      form={form}
      onFinish={props.onFinish}
      onFinishFailed={props.onFinishFailed}
    >
      <Form.Item
        name="body"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="NewComment.body_required_message" />,
          },
        ]}
        label={<FormattedMessage id="NewComment.body" />}
      >
        <TextArea autoSize={{ minRows: 2, maxRows: 8 }} />
      </Form.Item>
      <Form.Item style={{ textAlign: "right", marginBottom: 0 }}>
        <SubmitButton size="large" htmlType="submit" loading={loading}>
          <FormattedMessage id="NewTicketForm.submit" />
        </SubmitButton>
      </Form.Item>
    </Form>
  );
};

export default NewCommentForm;
