import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";

const NavbarContainer = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    display: none;
  }
`;

const MobileNavBar = (props) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const authMethod = isAuthenticated ? logout : loginWithRedirect;
  const location = useLocation();
  let userName = null;
  let userEmail = null;

  if (user) {
    userName = user.name;
    userEmail = user.email;
  }

  const {
    authMessage,
    ticketsLink,
    servicesLink,
    invoicesLink,
    formsLink,
    changeToSwedish,
    changeToEnglish,
  } = props;

  const userDropdownMobile = isAuthenticated ? {
    type: "group",
    key: 1,
    label:
      <FormattedMessage id="NavBar.user" />,
    children: [
      {
        key: "user_logged_in",
        style: { cursor: "auto" },
        label:
          <span><FormattedMessage id="NavBar.loggedin_as" /> {userName}</span>,
      },
      {
        key: "user_email",
        style: { cursor: "auto" },
        label:
          <span>{userEmail}</span>
      },
      {
        type: "divider"
      },
      {
        key: "logout_mobile",
        style: { marginLeft: "auto" },
        label:
          <div onClick={() => authMethod()}>
            {authMessage}
          </div>,
      }
    ]
  } : {
    key: "login_mobile",
    style: { marginLeft: "auto" },
    label:
      <div onClick={() => authMethod()}>
        {authMessage}
      </div>,
  };

  const menuItems = [
    {
      key: 0,
      style: { padding: "0" },
      popupOffset: [10, 0],
      label:
        <MenuOutlined
          style={{
            fontSize: "20px",
            paddingLeft: "25px",
            paddingRight: "50px",
          }}
        />,
      children: [
        {
          key: "/",
          label:
            <Link to="/">
              <FormattedMessage id="NavBar.home" />
            </Link>,
        },
        ticketsLink,
        formsLink,
        servicesLink,
        invoicesLink,
        {
          type: 'group',
          label:
            <FormattedMessage id="NavBar.language" />,
          children: [
            {
              key: 'swedish',
              label:
                <div onClick={changeToSwedish}>Svenska</div>,
            },
            {
              key: 'english',
              label:
                <div onClick={changeToEnglish}>English</div>,
            },
          ]
        },
        userDropdownMobile,
      ],
    }
  ];

  return (
    <NavbarContainer>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[location.pathname]}
        defaultSelectedKeys={[location.pathname]}
        triggerSubMenuAction="click"
        items={menuItems}
        style={{
          lineHeight: "64px",
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          padding: "0",
        }}
      />
      {props.serviceAlert}
    </NavbarContainer>
  );
};

export default MobileNavBar;
