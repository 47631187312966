import React, { useEffect, useState } from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import { Form, Input, Upload, Button, Row, Col, Alert } from "antd";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import {
  INSERT_NEW_TICKET,
  GET_FORM_INSTRUCTIONS,
  GET_ADDITIONAL_FORMS,
} from "../../queries";
import { useAuth0 } from "@auth0/auth0-react";
import ReactMarkdown from "react-markdown";
import LogRocket from "logrocket";

import OnOffboardingForm from "./OnOffboardingForm";
import TicketCategory from "./TicketCategory";
import NewPasswordForm from "./NewPasswordForm";
import OrderForm from "./OrderForm";
import AuthorizationManagementForm from "./AuthorizationManagementForm";
import TicketTypeSelect from "./TicketTypeSelect";
import {
  GET_DEFAULT_FIELD_OPTIONS,
  GET_CUSTOM_FIELD_OPTIONS,
} from "../../queries";
import { userFeedbackNotification } from "../../Components/userFeedbackNotification";

const { TextArea } = Input;
const { Dragger } = Upload;

export function getDefaultTicketCategory(ticketType) {
  return ticketType === "support" ? "support" : "";
}

export function useCustomFieldOptions(ticketType, ticketCategory, ticketField) {
  let options = [];
  const systemDefaultOptionsQuery = useQuery(GET_DEFAULT_FIELD_OPTIONS, {
    variables: {
      ticket_field: `${ticketType}_${ticketCategory}/${ticketField}`,
    },
  });

  const systemOptionsQuery = useQuery(GET_CUSTOM_FIELD_OPTIONS, {
    variables: {
      ticket_field: `${ticketType}_${ticketCategory}/${ticketField}`,
    },
  });

  try {
    options = systemOptionsQuery.data.sheets_access_management.map(
      (item) => item.value
    );
  } catch { }

  if (options.length === 0) {
    try {
      options = systemDefaultOptionsQuery.data.sheets_access_management.map(
        (item) => item.value
      );
    } catch { }
  }

  return options;
}

export const SubmitButton = styled(Button)`
  background-color: #0B2962;
  color: white;
  border-color: #0B2962;
  &:hover {
    color: #0B2962;
    background-color: white;
    border-color: #0B2962;
  }
`;

const InstructionWrapper = styled(ReactMarkdown)`
  > p {
    margin-bottom: 0px;
  }
`;

const InstructionRow = styled(Row)`
  margin-bottom: 24px;
`;

const NewTicketForm = (props) => {
  const [attachmentName, setAttachmentName] = useState();
  const [attachmentType, setAttachmentType] = useState();
  const [attachmentBase64, setAttachmentBase64] = useState();
  const [ticketType, setTicketType] = useState("support");
  const [ticketCategory, setTicketCategory] = useState("support");
  const [fileList, setFileList] = useState([]);
  const [additionalForms, setAdditionalForms] = useState([]);
  const [form] = Form.useForm();
  const { user } = useAuth0();
  const { name, email } = user;
  let instructionText = "";

  useEffect(() => {
    let subject = "";

    if (ticketType !== "support" && ticketCategory) {
      subject = `${props.intl.formatMessage({
        id: `NewTicketForm.ticket_type.${ticketType}`,
      })} - ${props.intl.formatMessage({
        id: `NewTicketForm.category.${ticketCategory}`,
      })}`;
    }

    // Reset the form fields in order to clear any validation info.
    form.resetFields();

    // Apply the form specific subject and maintain ticket type and category from our state.
    form.setFieldsValue({
      subject,
      ticket_type: ticketType,
      ticket_category: ticketCategory,
    });
    // eslint-disable-next-line
  }, [ticketType, ticketCategory]);

  const instructionQuery = useQuery(GET_FORM_INSTRUCTIONS, {
    variables: {
      form_name: `${ticketType}_${ticketCategory}`,
    },
  });
  try {
    instructionText =
      instructionQuery.data.sheets_access_management[0].instruction_text;
  } catch { }

  const { data: additionalFormsData, loading: additionalFormsLoading } =
    useQuery(GET_ADDITIONAL_FORMS);
  useEffect(() => {
    if (additionalFormsLoading === false && additionalFormsData) {
      try {
        const data =
          additionalFormsData.sheets_access_management[0].additional_forms;
        const allowedForms = [
          "on_offboarding",
          "order",
          "authorization_management",
        ];

        if (data && data.length > 0) {
          const formNames = data
            .split(",")
            .map((item) => item.trim())
            .filter((item) => item.length && allowedForms.indexOf(item) !== -1);

          setAdditionalForms(formNames);
        }
      } catch { }
    }
  }, [additionalFormsData, additionalFormsLoading]);

  const onCompleted = () => {
    userFeedbackNotification(
      props.intl.formatMessage({
        id: "NewTicketForm.formSubmittedMessage",
      }),
      "success"
    );

    // Reset the form state.
    setTicketType("support");
    setTicketCategory(getDefaultTicketCategory("support"));

    // Reset file upload.
    setFileList([]);
    setAttachmentBase64(undefined);
    setAttachmentName(undefined);
    setAttachmentType(undefined);

    // Reset the form itself.
    form.resetFields();
  };

  const onError = () => {
    userFeedbackNotification(
      props.intl.formatMessage({
        id: "NewTicketForm.formSubmitErrorMessage",
      }),
      "error"
    );
  };

  const [insertTicket, { loading: insertLoading }] = useMutation(
    INSERT_NEW_TICKET,
    { onCompleted, onError }
  );

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
    // Limit to the latest uploaded file.
    // This is a workaround for a bug in Antd which allows
    // multiple files event hough the `multiple` prop is false.
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    if (file) {
      setAttachmentName(file.name);
      setAttachmentType(file.type);

      reader.readAsBinaryString(file);
    }
    reader.onload = function () {
      setAttachmentBase64(btoa(reader.result));
    };
    reader.onerror = function (err) {
      LogRocket.error("Unable to parse file.", err);
    };

    return false;
  };

  const handleTicketTypeChange = (value) => {
    setTicketType(value);
    setTicketCategory(getDefaultTicketCategory(value));
  };

  const handleTicketCategoryChange = (value) => {
    setTicketCategory(value);
  };

  const buildDescription = (values) => {
    let description = values.description;
    description += "\n\n";

    // Ticket type
    description += props.intl.formatMessage({
      id: "NewTicketForm.ticket_type",
    });
    description += ": ";
    description += values.ticket_type
      ? props.intl.formatMessage({
        id: `NewTicketForm.ticket_type.${values.ticket_type}`,
      })
      : "support";

    // Ticket category
    description += "\n";
    description += props.intl.formatMessage({
      id: "NewTicketForm.ticket_category",
    });
    description += ": ";
    description += props.intl.formatMessage({
      id: `NewTicketForm.category.${ticketCategory}`,
    });

    if (
      ticketType === "authorization_management" &&
      ticketCategory === "new_password"
    ) {
      description += "\n\n";

      // System
      description += props.intl.formatMessage({
        id: "NewTicketForm.system",
      });
      description += ": ";
      description += values.system;

      // Cellphone
      description += "\n";
      description += props.intl.formatMessage({
        id: "NewTicketForm.cellphone_number",
      });
      description += ": ";
      description += values.cellphone_number;
    } else if (
      ticketType === "authorization_management" &&
      (ticketCategory === "new_authority" ||
        ticketCategory === "changed_authority")
    ) {
      description += "\n\n";

      // Employee name
      description += props.intl.formatMessage({
        id: "NewTicketForm.employee_name",
      });
      description += ": ";
      description += values.employee_name;

      // Authorization effective date
      description += "\n";
      description += props.intl.formatMessage({
        id: "NewTicketForm.authorization_effective_date",
      });
      description += ": ";
      description += dayjs(values.authorization_effective_date).format(
        "YYYY-MM-DD"
      );
    } else if (
      ticketType === "on_offboarding" &&
      ticketCategory === "new_employee"
    ) {
      description += "\n\n";

      // Employee name
      description += props.intl.formatMessage({
        id: "NewTicketForm.employee_name",
      });
      description += ": ";
      description += values.employee_name;

      // Employee start date
      description += "\n";
      description += props.intl.formatMessage({
        id: "NewTicketForm.employee_start_date",
      });
      description += ": ";
      description += dayjs(values.employee_start_date).format("YYYY-MM-DD");

      // Employee products
      if (values.products) {
        description += "\n";
        description += props.intl.formatMessage({
          id: "NewTicketForm.employee_products",
        });
        description += ": ";
        description += values.products;
      }

      // Cellphone
      description += "\n";
      description += props.intl.formatMessage({
        id: "NewTicketForm.cellphone_number",
      });
      description += ": ";
      description += values.cellphone_number;
    } else if (
      ticketType === "on_offboarding" &&
      ticketCategory === "end_employment"
    ) {
      description += "\n\n";

      // Employee name
      description += props.intl.formatMessage({
        id: "NewTicketForm.employee_name",
      });
      description += ": ";
      description += values.employee_name;

      // End of employment effective date
      description += "\n";
      description += props.intl.formatMessage({
        id: "NewTicketForm.end_date",
      });
      description += ": ";
      description += dayjs(values.authorization_effective_date).format(
        "YYYY-MM-DD"
      );
    } else if (ticketType === "order" && ticketCategory === "products") {
      description += "\n\n";

      // Product
      if (values.product) {
        description += props.intl.formatMessage({
          id: "NewTicketForm.products",
        });
        description += ": ";
        description += values.product;
      }
    } else if (ticketType === "order" && ticketCategory === "services") {
      description += "\n\n";

      // Service
      if (values.service) {
        description += props.intl.formatMessage({
          id: "NewTicketForm.services",
        });
        description += ": ";
        description += values.service;
      }
    } else if (ticketType === "order" && ticketCategory === "reports") {
      description += "\n\n";

      // Report
      if (values.report) {
        description += props.intl.formatMessage({
          id: "NewTicketForm.reports",
        });
        description += ": ";
        description += values.report;
      }
    }

    return description;
  };

  const buildFormFields = (values) => {
    const formFields = {
      ticketType,
      ticketCategory,
    };

    if (ticketType === "on_offboarding" && ticketCategory === "new_employee") {
      formFields.employee_name = values.employee_name;
      formFields.employee_start_date = values.employee_start_date;
      if (values.products) {
        formFields.products = values.products.join(", ");
      }
      formFields.cellphone_number = values.cellphone_number;
    } else if (
      ticketType === "on_offboarding" &&
      ticketCategory === "end_employment"
    ) {
      formFields.employee_name = values.employee_name;
      formFields.end_date = values.end_date;
    } else if (
      ticketType === "authorization_management" &&
      ticketCategory === "new_password"
    ) {
      formFields.system = values.system;
      formFields.cellphone_number = values.cellphone_number;
    } else if (
      ticketType === "authorization_management" &&
      ticketCategory === "new_authority"
    ) {
      formFields.employee_name = values.employee_name;
      formFields.authorization_effective_date =
        values.authorization_effective_date;
    } else if (
      ticketType === "authorization_management" &&
      ticketCategory === "changed_authority"
    ) {
      formFields.employee_name = values.employee_name;
      formFields.authorization_effective_date =
        values.authorization_effective_date;
    }

    return JSON.stringify(formFields);
  };

  const handleSubmit = (values) => {
    const description = buildDescription(values);
    const formFields = buildFormFields(values);
    const tickettype = values.ticket_type ? values.ticket_type : "support";

    insertTicket({
      variables: {
        description,
        subject: values.subject,
        ticket_type: tickettype,
        ticket_category: ticketCategory,
        form_fields: formFields,
        attachment_name: attachmentName,
        attachment_type: attachmentType,
        attachment_base64: attachmentBase64,
      },
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 3 },
      xl: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      lg: { span: 18 },
      xl: { span: 12 },
    },
  };

  const buttonsFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      lg: {
        span: 18,
        offset: 3,
      },
      xl: {
        span: 12,
        offset: 6,
      },
    },
  };

  let additionalFormFields = null;

  if (ticketType === "on_offboarding") {
    additionalFormFields = (
      <OnOffboardingForm
        ticketType={ticketType}
        ticketCategory={ticketCategory}
      />
    );
  } else if (ticketType === "support" && ticketCategory === "new_password") {
    additionalFormFields = (
      <NewPasswordForm
        ticketType={ticketType}
        ticketCategory={ticketCategory}
      />
    );
  } else if (ticketType === "order") {
    additionalFormFields = (
      <OrderForm ticketType={ticketType} ticketCategory={ticketCategory} />
    );
  } else if (ticketType === "authorization_management") {
    additionalFormFields = (
      <AuthorizationManagementForm
        ticketType={ticketType}
        ticketCategory={ticketCategory}
      />
    );
  }

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      {...formItemLayout}
      initialValues={{
        ticket_type: ticketType,
        ticket_category: ticketType === "support" ? "support" : "",
      }}
    >
      <Form.Item label={<FormattedMessage id="NewTicketForm.name" />}>
        <span>{name}</span>
      </Form.Item>
      <Form.Item label={<FormattedMessage id="NewTicketForm.email" />}>
        <span>{email}</span>
      </Form.Item>
      <TicketTypeSelect
        additionalForms={additionalForms}
        handleTicketTypeChange={handleTicketTypeChange}
      />
      {ticketCategory !== "support" ? (
        <TicketCategory
          form={form}
          handleTicketCategoryChange={handleTicketCategoryChange}
          ticketType={ticketType}
        />
      ) : null}
      <InstructionRow>
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 12, offset: 6 }}
        >
          {instructionText.length > 0 ? (
            <Alert
              message={
                <InstructionWrapper linkTarget="_blank">
                  {instructionText}
                </InstructionWrapper>
              }
              type="info"
              showIcon
            />
          ) : null}
        </Col>
      </InstructionRow>
      <Form.Item
        name="subject"
        label={<FormattedMessage id="NewTicketForm.subject" />}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.subject_required_message" />
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={<FormattedMessage id="NewTicketForm.description" />}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.description_required_message" />
            ),
          },
        ]}
      >
        <TextArea autoSize={{ minRows: 8, maxRows: 30 }} />
      </Form.Item>
      {additionalFormFields}
      <Form.Item {...buttonsFormItemLayout}>
        <Dragger
          name="file"
          onChange={handleFileChange}
          beforeUpload={handleFileUpload}
          multiple={false}
          fileList={fileList}
        >
          <PaperClipOutlined />{" "}
          <FormattedMessage id="NewTicketForm.attachment_description" />
        </Dragger>
      </Form.Item>
      <Form.Item {...buttonsFormItemLayout}>
        <SubmitButton size="large" htmlType="submit" loading={insertLoading}>
          <FormattedMessage id="NewTicketForm.submit" />
        </SubmitButton>
      </Form.Item>
    </Form>
  );
};

export default injectIntl(NewTicketForm);
