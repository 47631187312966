import gql from "graphql-tag";

export const TICKETS_CACHE_SUBSCRIPTION = gql`
  subscription MyTicketsCache {
    sp_backend_ticket_cache {
      ticket_id
      description
      subject
    }
  }
`;

export const MY_TICKETS_WITH_COMMENTS_COUNT = gql`
  subscription MyTicketsWithCommentsCount(
    $allowedStatuses: [String!]
    $fromDate: timestamptz
    $email: String
  ) {
    raw_zendesk_ticket_aggregate(
      where: {
        status: { _in: $allowedStatuses }
        is_public: { _eq: true }
        ticket_form_id: { _neq: "360000618759" }
        ticket_to_user_by_userid: { email: { _ilike: $email } }
        _or: [
          { _not: { ticket_to_tag_by_ticketid: {} } }
          {
            _not: {
              ticket_to_tag_by_ticketid: {
                tag: { _in: ["sys_xcvkspvisaej", "sys_opd_showksp"] }
              }
            }
          }
        ]
        updated_at: { _gte: $fromDate }
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export const TICKETS_WITH_COMMENTS_SUBSCRIPTION = gql`
  subscription MyTicketsWithComments(
    $allowedStatuses: [String!]
    $email: String
    $fromDate: timestamptz
    $orderBy: [raw_zendesk_ticket_order_by!]
    $limit: Int!
    $offset: Int!
  ) {
    raw_zendesk_ticket(
      where: {
        status: { _in: $allowedStatuses }
        is_public: { _eq: true }
        ticket_form_id: { _neq: "360000618759" }
        ticket_to_user_by_userid: { email: { _ilike: $email } }
        _or: [
          { _not: { ticket_to_tag_by_ticketid: {} } }
          {
            _not: {
              ticket_to_tag_by_ticketid: {
                tag: { _in: ["sys_xcvkspvisaej", "sys_opd_showksp"] }
              }
            }
          }
        ]
        updated_at: { _gte: $fromDate }
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      type
      priority
      description
      status
      subject
      ticket_form_id
      created_at
      updated_at
      ticket_to_user_by_userid {
        email
        id
        name
      }
      ticket_to_comment_by_ticketid(
        order_by: { created: desc }
        where: { public: { _eq: true } }
      ) {
        body
        created
        public
        comment_to_user_by_userid {
          name
        }
      }
      ticket_to_comment_cache_by_ticket_id(
        order_by: { created: desc }
        where: { public: { _eq: true } }
      ) {
        body
        created
        public
        comment_cache_to_user_by_user_id {
          name
        }
      }
    }
  }
`;

export const DISRUPTIONS_WITH_COMMENTS_COUNT = gql`
  subscription DisruptionsWithCommentsCount(
    $allowedStatuses: [String!]
    $fromDate: timestamptz
  ) {
    raw_zendesk_ticket_aggregate(
      where: {
        status: { _in: $allowedStatuses }
        is_public: { _eq: true }
        ticket_to_tag_by_ticketid: { tag: { _eq: "sys_opd_showksp" } }
        updated_at: { _gte: $fromDate }
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export const DISRUPTIONS_WITH_COMMENTS_SUBSCRIPTION = gql`
  subscription DisruptionsWithComments(
    $allowedStatuses: [String!]
    $fromDate: timestamptz
    $orderBy: [raw_zendesk_ticket_order_by!]
    $limit: Int!
    $offset: Int!
  ) {
    raw_zendesk_ticket(
      where: {
        status: { _in: $allowedStatuses }
        is_public: { _eq: true }
        ticket_to_tag_by_ticketid: { tag: { _eq: "sys_opd_showksp" } }
        updated_at: { _gte: $fromDate }
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      subject
      description
      status
      custom_status
      created_at
      updated_at
      ticket_to_tag_by_ticketid {
        tag
      }
      ticket_to_comment_by_ticketid(
        order_by: { created: desc }
        where: { public: { _eq: true } }
      ) {
        body
        created
        public
        comment_to_user_by_userid {
          name
        }
      }
    }
  }
`;

export const DISRUPTION_STATUSES = gql`
  subscription DisruptionStatuses($allowedStatuses: [String!]) {
    raw_zendesk_ticket(
      where: {
        status: { _in: $allowedStatuses }
        is_public: { _eq: true }
        ticket_to_tag_by_ticketid: { tag: { _eq: "sys_opd_showksp" } }
      }
      distinct_on: custom_status
    ) {
      custom_status
      ticket_to_tag_by_ticketid {
        tag
      }
    }
  }
`;

export const IS_ADMIN = gql`
  query IsAdmin($email: String!) {
    sheets_users(where: { email: { _ilike: $email } }) {
      is_org_admin
    }
  }
`;

export const IS_INVOICE_READER = gql`
  query IsInvoiceReader($email: String!) {
    sheets_users(where: { email: { _ilike: $email } }) {
      is_invoice_reader
    }
  }
`;

export const IS_LOGICMONITOR_USER = gql`
  query IsLogicMonitorUser($email: String!) {
    sheets_users(where: { email: { _ilike: $email } }) {
      is_logicmonitor_user
    }
  }
`;

export const INSERT_NEW_TICKET = gql`
  mutation InsertNewTicket(
    $description: String!
    $subject: String!
    $ticket_type: String!
    $ticket_category: String
    $form_fields: String
    $attachment_name: String
    $attachment_type: String
    $attachment_base64: String
  ) {
    insert_sp_backend_ticket_cache(
      objects: {
        description: $description
        subject: $subject
        ticket_type: $ticket_type
        ticket_category: $ticket_category
        form_fields: $form_fields
        attachment_name: $attachment_name
        attachment_type: $attachment_type
        attachment_base64: $attachment_base64
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_NEW_COMMENT = gql`
  mutation InsertNewComment(
    $ticket_id: bigint!
    $email: String!
    $public: Boolean!
    $body: String!
    $created: timestamptz!
  ) {
    insert_sp_backend_comments_cache_one(
      object: {
        ticket_id: $ticket_id
        email: $email
        public: $public
        body: $body
        created: $created
      }
    ) {
      id
    }
  }
`;

export const GET_FORM_INSTRUCTIONS = gql`
  query GetFormInstructions($form_name: String!) {
    sheets_access_management(
      where: { instruction_form: { _eq: $form_name } }
      distinct_on: instruction_form
      order_by: { instruction_form: asc, org_id: desc }
    ) {
      instruction_text
    }
  }
`;

export const GET_ADDITIONAL_FORMS = gql`
  query GetAdditionalForms {
    sheets_access_management(
      where: {
        org_id: { _neq: 0 }
        _and: { additional_forms: { _is_null: false } }
      }
      distinct_on: org_id
    ) {
      additional_forms
    }
  }
`;

export const GET_DEFAULT_FIELD_OPTIONS = gql`
  query GetDefaultFieldOptions($ticket_field: String!) {
    sheets_access_management(
      where: { ticket_field: { _eq: $ticket_field }, org_id: { _eq: 0 } }
    ) {
      value
    }
  }
`;

export const GET_CUSTOM_FIELD_OPTIONS = gql`
  query GetCustomFieldOptions($ticket_field: String!) {
    sheets_access_management(
      where: { ticket_field: { _eq: $ticket_field }, org_id: { _neq: 0 } }
    ) {
      value
    }
  }
`;

export const GET_KNOWLEDGE_BASE_URL = gql`
  query GetKnowledgeBaseUrl {
    sheets_access_management(
      where: {
        org_id: { _neq: 0 }
        _and: { knowledge_base_url: { _is_null: false } }
      }
      distinct_on: org_id
    ) {
      knowledge_base_url
    }
  }
`;

export const GET_REPORTS_URL = gql`
  query GetReportsUrl {
    sheets_access_management(
      where: { org_id: { _neq: 0 }, _and: { reports_url: { _is_null: false } } }
      distinct_on: org_id
    ) {
      reports_url
    }
  }
`;

export const GET_LOGIC_MONITOR_URL = gql`
  query GetLogicMonitorUrl {
    sheets_access_management(
      where: {
        org_id: { _neq: "0" }
        _and: { logic_monitor_url: { _is_null: false } }
      }
      distinct_on: org_id
    ) {
      logic_monitor_url
    }
  }
`;

export const GET_LEXICON_URL = gql`
  query GetLexiconUrl {
    sheets_access_management(
      where: {
        org_id: { _neq: "0" }
        _and: { lexicon_url: { _is_null: false } }
      }
      distinct_on: org_id
    ) {
      lexicon_url
    }
  }
`;
