import React from "react";
import { Layout } from "antd";
import Router from "../../Router";
import { Link } from "react-router-dom";
import NavBar from "../NavBar";
import styled from "styled-components";
import CookieConsent from "../CookieConsent";

const { Header } = Layout;

const CAG = styled.img`
  margin: 0 50px;
`;

const SkeletonLayout = () => {
  return (
    <>
      <Layout className="layout">
        <Header style={{ display: "flex", padding: 0 }}>
          <Link to="/">
            <CAG
              src={`${process.env.PUBLIC_URL}/logo.svg`}
              alt="CAG"
              width="80"
            />
          </Link>
          <NavBar />
        </Header>
        <CookieConsent />
        <Router />
      </Layout>
    </>
  );
};

export default SkeletonLayout;
