import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import LogRocket from "logrocket";

import {
  IS_ADMIN,
  IS_INVOICE_READER,
  IS_LOGICMONITOR_USER,
} from "../src/queries";

export const UserAccessContext = React.createContext();
export const useAccess = () => useContext(UserAccessContext);

export const UserAccessProvider = ({ children }) => {
  const { user } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(null);
  const [isInvoiceReader, setIsInvoiceReader] = useState(null);
  const [isLogicMonitorUser, setIsLogicMonitorUser] = useState(null);

  // Check if the user is an admin.
  const [getIsAdmin, adminResult] = useLazyQuery(IS_ADMIN);
  const { data: adminData, error: adminError } = adminResult;
  const isAdminDataLoading = adminData ? adminData.loading : false;
  if (adminError) LogRocket.error(adminError);

  useEffect(() => {
    if (user && user.email) {
      getIsAdmin({
        variables: { email: user.email },
      });
    }

    if (adminData && adminData.sheets_users[0]) {
      setIsAdmin(adminData.sheets_users[0].is_org_admin);
    }
  }, [user, adminData, getIsAdmin, setIsAdmin]);

  // Check if the user is an invoice reader.
  const [getIsInvoiceReader, invoiceReaderResult] =
    useLazyQuery(IS_INVOICE_READER);
  const { data: invoiceReaderData, error: invoiceReaderError } =
    invoiceReaderResult;
  const isInvoiceReaderLoading = invoiceReaderResult
    ? invoiceReaderResult.loading
    : false;
  if (invoiceReaderError) LogRocket.error(invoiceReaderError);

  useEffect(() => {
    if (user && user.email) {
      getIsInvoiceReader({
        variables: { email: user.email },
      });
    }

    if (invoiceReaderData && invoiceReaderData.sheets_users[0]) {
      setIsInvoiceReader(invoiceReaderData.sheets_users[0].is_invoice_reader);
    }
  }, [getIsInvoiceReader, invoiceReaderData, user]);

  // Check if the user is LogicMonitor user.
  const [getIsLogicMonitorUser, logicMonitorResult] =
    useLazyQuery(IS_LOGICMONITOR_USER);
  const { data: logicMonitorData, error: logicMonitorError } =
    logicMonitorResult;
  const isLogicMonitorUserLoading = logicMonitorResult
    ? logicMonitorResult.loading
    : false;
  if (logicMonitorError) LogRocket.error(logicMonitorError);

  useEffect(() => {
    if (user && user.email) {
      getIsLogicMonitorUser({
        variables: { email: user.email },
      });
    }

    if (logicMonitorData && logicMonitorData.sheets_users[0]) {
      setIsLogicMonitorUser(
        logicMonitorData.sheets_users[0].is_logicmonitor_user
      );
    }
  }, [getIsLogicMonitorUser, logicMonitorData, user]);

  /***************/

  return (
    <UserAccessContext.Provider
      value={{
        isAdmin,
        isAdminDataLoading,
        isInvoiceReader,
        isInvoiceReaderLoading,
        isLogicMonitorUser,
        isLogicMonitorUserLoading,
      }}
    >
      {children}
    </UserAccessContext.Provider>
  );
};
