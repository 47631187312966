import React from "react";

import OrderFormProducts from "./OrderFormProducts";
import OrderFormServices from "./OrderFormServices";
import OrderFormReports from "./OrderFormReports";

const OrderForm = (props) => {
  const { ticketType, ticketCategory } = props;
  let additionalFormFields = null;

  switch (ticketCategory) {
    case "products":
      additionalFormFields = (
        <OrderFormProducts
          ticketType={ticketType}
          ticketCategory={ticketCategory}
        />
      );
      break;
    case "services":
      additionalFormFields = (
        <OrderFormServices
          ticketType={ticketType}
          ticketCategory={ticketCategory}
        />
      );
      break;
    case "reports":
      additionalFormFields = (
        <OrderFormReports
          ticketType={ticketType}
          ticketCategory={ticketCategory}
        />
      );
      break;
    default:
      additionalFormFields = null;
  }

  return <>{additionalFormFields}</>;
};

export default OrderForm;
