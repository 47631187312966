import React from "react";
import { Form } from "antd";
import { Input, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { useCustomFieldOptions } from "./NewTicketForm";

const { Option } = Select;

const NewPasswordForm = (props) => {
  const { ticketType, ticketCategory } = props;
  const systemOptions = useCustomFieldOptions(
    ticketType,
    ticketCategory,
    "system"
  );

  return (
    <>
      {systemOptions.length > 0 ? (
        <Form.Item
          name="system"
          label={<FormattedMessage id="NewTicketForm.system" />}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="NewTicketForm.system_required_message" />
              ),
            },
          ]}
        >
          <Select>
            {systemOptions.map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      ) : null}
      <Form.Item
        name="cellphone_number"
        label={<FormattedMessage id="NewTicketForm.cellphone_number" />}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.cellphone_number_required_message" />
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default NewPasswordForm;
