import translation_sv from "../../translations/sv.json";
import translation_en from "../../translations/en.json";
import seSV from "antd/lib/locale-provider/sv_SE";
import enUs from "antd/lib/locale-provider/en_US";

const translations = {
  sv: translation_sv,
  en: translation_en,
};

export const languageOptions = [
  { locale: "sv", translation: translations.sv, antDesign: seSV },
  { locale: "en", translation: translations.en, antDesign: enUs },
];
