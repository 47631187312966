import { useQuery } from "@apollo/client";
import { GET_LEXICON_URL } from "../../../queries";

export default function useLexiconUrl() {
  let lexiconUrl = null;
  const { data, lexiconLoading } = useQuery(GET_LEXICON_URL);

  try {
    lexiconUrl = data.sheets_access_management[0].lexicon_url;
  } catch {}

  return { lexiconUrl, lexiconLoading };
}
