import React from "react";
import { Form, Collapse } from "antd";
import { useMutation } from "@apollo/client";
import { injectIntl } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";

import NewCommentForm from "./NewCommentForm";
import { INSERT_NEW_COMMENT } from "../../../queries";
import { userFeedbackNotification } from "../../../Components/userFeedbackNotification";

const { Panel } = Collapse;

const showInserError = (props) => {
  userFeedbackNotification(
    props.intl.formatMessage({
      id: "NewComment.insertFailedMessage",
    }),
    "error"
  );
};

const NewComment = (props) => {
  const { ticketId } = props;
  const [form] = Form.useForm();
  const { user } = useAuth0();
  const onCompleted = () => {
    form.resetFields();
  };
  const onError = (error) => {
    showInserError(props);
  };
  const [insertComment, { loading }] = useMutation(INSERT_NEW_COMMENT, {
    onCompleted,
    onError,
  });

  const handleCreate = (values) => {
    insertComment({
      variables: {
        ticket_id: ticketId,
        email: user.email,
        public: true,
        body: values.body,
        created: new Date(),
      },
    });
  };

  const handleCreateFailed = () => {
    showInserError(props);
  };

  return (
    <Collapse defaultActiveKey={["0"]} style={{ marginTop: 10 }}>
      <Panel key="0" header="Ny kommentar" showArrow={false} forceRender={true}>
        <NewCommentForm
          form={form}
          loading={loading}
          onFinish={handleCreate}
          onFinishFailed={handleCreateFailed}
        />
      </Panel>
    </Collapse>
  );
};

export default injectIntl(NewComment);
