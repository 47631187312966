import React from "react";
import { Form } from "antd";
import { Input, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";

const EndEmploymentForm = (props) => {
  return (
    <>
      <Form.Item
        name="employee_name"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.employee_name_required_message" />
            ),
          },
        ]}
        label={<FormattedMessage id="NewTicketForm.employee_name" />}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="end_date"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.end_date_required_message" />
            ),
          },
        ]}
        label={<FormattedMessage id="NewTicketForm.end_date" />}
      >
        <DatePicker />
      </Form.Item>
    </>
  );
};

export default EndEmploymentForm;
