import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { useCustomFieldOptions } from "./NewTicketForm";

const { Option } = Select;

const OrderFormServices = (props) => {
  const { ticketType, ticketCategory } = props;
  const serviceOptions = useCustomFieldOptions(
    ticketType,
    ticketCategory,
    "services"
  );

  return serviceOptions.length > 0 ? (
    <Form.Item
      name="service"
      label={<FormattedMessage id="NewTicketForm.services" />}
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage id="NewTicketForm.services_required_message" />
          ),
        },
      ]}
    >
      <Select mode="multiple" showArrow>
        {serviceOptions.map((option, index) => {
          return (
            <Option key={index} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  ) : null;
};

export default OrderFormServices;
