import React from "react";

import "./App.css";

import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "./Components/Language/Language";
import SkeletonLayout from "./Components/SkeletonLayout";

const App = () => {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <SkeletonLayout />
      </BrowserRouter>
    </LanguageProvider>
  );
};

export default App;
