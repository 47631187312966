import React from "react";
import { Alert } from "antd";

const DeprecationAlert = () => {
  return (
    <Alert
      description={<span>Denna site kommer stängas ner den sista oktober 2024 och vi hänvisar från och med nu till <a href="https://cag.zendesk.com">cag.zendesk.com</a> för motsvarande funktionalitet. Vid eventuella frågor kontakta er KAM eller TAM från CAG.</span>}
      type="warning"
      showIcon
    />
  );
};

export default DeprecationAlert;
