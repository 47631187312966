import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { getDefaultTicketCategory } from "./NewTicketForm";

const { Option } = Select;

const TicketCategory = (props) => {
  let ticketCategoryDropdown = null;
  const { handleTicketCategoryChange, ticketType, form } = props;

  useEffect(() => {
    form.setFieldsValue({
      ticket_category: getDefaultTicketCategory(ticketType),
    });
    // eslint-disable-next-line
  }, [ticketType]);

  switch (ticketType) {
    case "support":
      ticketCategoryDropdown = (
        <>
          <Form.Item
            name="ticket_category"
            label={<FormattedMessage id="NewTicketForm.ticket_category" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="NewTicketForm.ticket_category_required_message" />
                ),
              },
            ]}
          >
            <Select onChange={handleTicketCategoryChange} disabled>
              <Option value="support">
                <FormattedMessage id="NewTicketForm.category.support" />
              </Option>
            </Select>
          </Form.Item>
        </>
      );
      break;
    case "on_offboarding":
      ticketCategoryDropdown = (
        <>
          <Form.Item
            name="ticket_category"
            label={<FormattedMessage id="NewTicketForm.ticket_category" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="NewTicketForm.ticket_category_required_message" />
                ),
              },
            ]}
          >
            <Select onChange={handleTicketCategoryChange}>
              <Option value="new_employee">
                <FormattedMessage id="NewTicketForm.category.new_employee" />
              </Option>
              <Option value="end_employment">
                <FormattedMessage id="NewTicketForm.category.end_employment" />
              </Option>
            </Select>
          </Form.Item>
        </>
      );
      break;
    case "order":
      ticketCategoryDropdown = (
        <>
          <Form.Item
            name="ticket_category"
            label={<FormattedMessage id="NewTicketForm.ticket_category" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="NewTicketForm.ticket_category_required_message" />
                ),
              },
            ]}
          >
            <Select onChange={handleTicketCategoryChange}>
              <Option value="products">
                <FormattedMessage id="NewTicketForm.category.products" />
              </Option>
              <Option value="services">
                <FormattedMessage id="NewTicketForm.category.services" />
              </Option>
              <Option value="reports">
                <FormattedMessage id="NewTicketForm.category.reports" />
              </Option>
            </Select>
          </Form.Item>
        </>
      );
      break;
    case "authorization_management":
      ticketCategoryDropdown = (
        <>
          <Form.Item
            name="ticket_category"
            label={<FormattedMessage id="NewTicketForm.ticket_category" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="NewTicketForm.ticket_category_required_message" />
                ),
              },
            ]}
          >
            <Select onChange={handleTicketCategoryChange}>
              <Option value="new_password">
                <FormattedMessage id="NewTicketForm.category.new_password" />
              </Option>
              <Option value="new_authority">
                <FormattedMessage id="NewTicketForm.category.new_authority" />
              </Option>
              <Option value="changed_authority">
                <FormattedMessage id="NewTicketForm.category.changed_authority" />
              </Option>
            </Select>
          </Form.Item>
        </>
      );
      break;
    default:
      ticketCategoryDropdown = <></>;
  }

  return <>{ticketCategoryDropdown}</>;
};

export default TicketCategory;
