import React, { useEffect, useState } from "react";
import { Table, Switch } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";

import ClosedTicketTable from "./ClosedTicketTable";
import { useAccess } from "../../UserAccessContext";
import OpenTicketsTable from "./OpenTicketsTable";
import CachedTicketsAlert from "./CachedTicketsAlert";

export const StyledTable = styled(Table)`
  margin-bottom: 50px;
`;

export const AdminToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  min-height: 43px;
`;

export const StyledP = styled.p`
  text-align: right;
  margin-right: 10px;
  margin-bottom: 0;
`;

export const Headline = styled.h1`
  margin: 10px 0 30px 0;
  color: #0B2962;
  font-size: 25px;
  font-weight: 200;
  letter-spacing: 0.0625em;
  text-align: left;
`;

const TicketsPage = (props) => {
  const { isAdmin } = useAccess();
  const [viewAll, setViewAll] = useState(false);

  useEffect(() => {
    if (isAdmin) {
      setViewAll(true);
    }
  }, [isAdmin]);

  const toggleViewAll = () => {
    viewAll ? setViewAll(false) : setViewAll(true);
  };

  const adminToggle = isAdmin ? (
    <AdminToggleWrapper>
      <StyledP>
        <FormattedMessage id="Tickets.view_organizations_tickets" />
      </StyledP>
      <Switch checked={viewAll} onChange={toggleViewAll} />
    </AdminToggleWrapper>
  ) : null;

  return (
    <>
      <CachedTicketsAlert />
      <OpenTicketsTable viewAll={viewAll} adminToggle={adminToggle} />
      <ClosedTicketTable viewAll={viewAll} />
    </>
  );
};

export default injectIntl(TicketsPage);
