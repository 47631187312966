import React from "react";
import { Tag } from "antd";

const color = {
  sys_opd_planned_disruption: "blue",
  sys_opd_maintenance: "blue",
  sys_opd_partially_down: "orange",
  sys_opd_down: "red",
};

const Status = (props) => {
  const { status, text } = props;
  return <Tag color={color[status]}>{text}</Tag>;
};

export default Status;
