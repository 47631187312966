import React, { useEffect, useState } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const ConsentBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0B2962;
  z-index: 1000;

  > span {
    color: #fff;
    margin-right: 20px;
  }
`;

export const OkButton = styled(Button)`
  background-color: transparent;
  color: white;
  border-color: #fff;
  align-self: center;
  &:hover {
    color: #0B2962;
    background-color: white;
    border-color: #0B2962;
  }
`;

const hasAcceptedCookies = function () {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith("cookie_consent="));
};

const setCookie = function () {
  document.cookie = `cookie_consent=true; max-age=${60 * 60 * 24 * 365}`;
};

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  // Get initial state.
  useEffect(() => {
    const hasAccepted = hasAcceptedCookies();
    setShowConsent(!hasAccepted);

    if (hasAccepted) {
      setCookie();
    }
  }, []);

  const acceptCookies = () => {
    setCookie();
    setShowConsent(false);
  };

  return (
    <>
      {showConsent ? (
        <ConsentBanner>
          <span>
            <FormattedMessage id="CookieConsent.cookie_information" />
          </span>
          <OkButton onClick={acceptCookies}>
            <FormattedMessage id="CookieConsent.ok_button" />
          </OkButton>
        </ConsentBanner>
      ) : null}
    </>
  );
};

export default CookieConsent;
