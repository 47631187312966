import React from "react";
import { FormattedMessage } from "react-intl";

const ScreenSharingLink = ({ linkColor }) => {
  return (
    <a
      href="https://cag.beyondtrustcloud.com/"
      target="_blank"
      rel="noreferrer"
      style={{
        color: linkColor,
        "&:hover": {
          color: linkColor,
        }
      }}
    >
      <FormattedMessage id="ScreenSharing.link" />
    </a>
  );
};

export default ScreenSharingLink;
