import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import InvoiceTable from "./InvoiceTable";
import { CloudDownloadOutlined } from "@ant-design/icons";

const StyledButton = styled(Button)`
  background-color: transparent;
  color: #0B2962;
  border-color: #0B2962;
  align-self: center;
  &:hover {
    color: white;
    background-color: #0B2962;
    border-color: #0B2962;
  }

  &:focus {
    background-color: transparent;
    color: #0B2962;
    border-color: #0B2962;
  }

  @media (max-width: 576px) {
    height: 40px;
    font-size: 20px;
    border-radius: 2px;
  }
`;

const ButtonText = styled.span`
  @media (max-width: 576px) {
    display: none !important;
  }
`;

let invoices_url;
switch (window.location.origin) {
  case "https://sp-frontend-stage.ms.cag.se":
    invoices_url =
      "https://box-integration.azurewebsites.net/api/box-integration-stage";

    break;
  case "https://portal.cag.se":
    invoices_url =
      "https://box-integration.azurewebsites.net/api/box-integration-prod";

    break;
  case "http://localhost:3000":
    invoices_url = "http://localhost:7071/api/box-integration";

    break;
  case "http://localhost:3001":
    invoices_url = "http://localhost:7071/api/box-integration";

    break;
  default:
    invoices_url =
      "https://box-integration.azurewebsites.net/api/box-integration-dev";
}

export const AdminToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledP = styled.p`
  margin-right: 10px;
`;

const Invoices = () => {
  const auth0 = useAuth0();
  const user = auth0.user;
  const [invoices, setInvoices] = useState();
  const [loading, setLoading] = useState(true);

  const getInvoices = async () => {
    try {
      const token = await auth0.getAccessTokenSilently();
      if (user && user.email && token) {
        const response = await fetch(invoices_url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Token: token,
            Email: user.email,
          },
        });
        if (response.status === 200) {
          const data = await response.json();
          setLoading(false);
          const newInvoices = data.map((c) => {
            return {
              id: c.id,
              name: c.name,
              downloadUrl: c.downloadUrl,
              href: <a href={c.downloadUrl}>{c.name}</a>,
              created_at: c.created_at,
              updated_at: c.updated_at,
              action: (
                <a href={c.downloadUrl} rel="noopener noreferrer" download>
                  <StyledButton icon={<CloudDownloadOutlined />}>
                    <ButtonText>
                      <FormattedMessage id="Invoices.download" />
                    </ButtonText>
                  </StyledButton>
                </a>
              ),
            };
          });
          setInvoices(newInvoices);
        } else {
          // TODO handle 40x etc
          setLoading(false);
          //console.error(response.status + response.statusText)
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (invoices === undefined && user !== undefined) {
      getInvoices();
    }
    // eslint-disable-next-line
  }, [invoices, user]);

  return (
    <>
      <h1 style={{ marginBottom: "20px" }}>
        <FormattedMessage id="Invoices.title" />
      </h1>
      <InvoiceTable invoices={invoices} loading={loading} />
    </>
  );
};

export default Invoices;
