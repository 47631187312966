import React, { useEffect, useState } from "react";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { useAuth0 } from "@auth0/auth0-react";

const requestSanitizer = (request) => {
  if (request.url.toLowerCase().indexOf("/oauth/token") !== -1) {
    request.body = null;
  }

  if (request && request.headers && request.headers.Authorization) {
    request.headers.Authorization = null;
  }

  return request;
};

const responseSanitizer = (response) => {
  if (response.url.toLowerCase().indexOf("/oauth/token") !== -1) {
    response.body = null;
  }

  if (response && response.headers && response.headers.Authorization) {
    response.headers.Authorization = null;
  }

  return response;
};

const hasAnalyticsOptOutCookie = () => {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith("analytics_opt_out="));
};

const initLogRocket = (user) => {
  if (window.location.origin === "https://portal.cag.se") {
    LogRocket.init("4pucdo/portal-cag", {
      network: { requestSanitizer, responseSanitizer },
      shouldCaptureIP: false,
    });
  } else {
    LogRocket.init("4pucdo/portal-cag-dev", {
      network: { requestSanitizer, responseSanitizer },
    });
  }

  if (user && user.email) {
    LogRocket.identify(user.email, {
      name: user.name,
      email: user.email,
    });
  }

  setupLogRocketReact(LogRocket);
};

export const LogRocketController = ({ children }) => {
  const { user } = useAuth0();
  const hasAnalyticsOptOut = hasAnalyticsOptOutCookie();
  const [isInitialized, setIsInitialize] = useState(false);

  useEffect(() => {
    if (
      !isInitialized &&
      !hasAnalyticsOptOut &&
      user &&
      window.location.origin !== "http://localhost:3000"
    ) {
      initLogRocket(user);
      setIsInitialize(true);
    }
    // eslint-disable-next-line
  }, [user, hasAnalyticsOptOut]);

  return <>{children}</>;
};
