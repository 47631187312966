import React, { useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Client from "./apolloSetup";
import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { useAuth0 } from "@auth0/auth0-react";

import { UserAccessProvider } from "./UserAccessContext";
import { LogRocketController } from "./LogRocketController";

let graphql_origin;
let protocol;
let clientID;

// A function that routes the user to the right place
// after login
switch (window.location.origin) {
  case "https://sp-frontend-stage.ms.cag.se":
    graphql_origin = "sp-backend-stage.ms.cag.se";
    protocol = "wss";
    clientID = process.env.REACT_APP_AUTH0_CLIENT_ID_STAGE;

    break;
  case "https://portal.cag.se":
    graphql_origin = "sp-backend.ms.cag.se";
    protocol = "wss";
    clientID = process.env.REACT_APP_AUTH0_CLIENT_ID_PROD;
    const browserHistory = createBrowserHistory({ basename: "" });
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: "b97da8dc-2137-4c25-8f15-35249ff7e8fd",
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    break;
  case "http://localhost:3000":
    graphql_origin = "localhost:8080";
    protocol = "ws";
    clientID = process.env.REACT_APP_AUTH0_CLIENT_ID_DEV;

    break;
  case "http://localhost:3001":
    graphql_origin = "localhost:8080";
    protocol = "ws";
    clientID = process.env.REACT_APP_AUTH0_CLIENT_ID_DEV;

    break;
  default:
    clientID = process.env.REACT_APP_AUTH0_CLIENT_ID_DEV;
    graphql_origin = "sp-backend-dev.ms.cag.se";
    protocol = "wss";
}

const AuthenticatedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [apolloClient] = useState(
    Client(graphql_origin, protocol, getAccessTokenSilently)
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

ReactDOM.render(
  <Auth0Provider
    clientId={clientID}
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    redirectUri={window.location.href}
  >
    <LogRocketController>
      <AuthenticatedApolloProvider>
        <UserAccessProvider>
          <App />
        </UserAccessProvider>
      </AuthenticatedApolloProvider>
    </LogRocketController>
  </Auth0Provider>,
  document.getElementById("root")
);
