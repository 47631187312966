import React from "react";

import NewPasswordForm from "./NewPasswordForm";
import ChangedAuthorityForm from "./ChangedAuthorityForm";

const AuthorizationManagementForm = (props) => {
  const { ticketType, ticketCategory } = props;
  let additionalFormFields = null;

  switch (ticketCategory) {
    case "new_password":
      additionalFormFields = (
        <NewPasswordForm
          ticketType={ticketType}
          ticketCategory={ticketCategory}
        />
      );
      break;
    case "new_authority":
    case "changed_authority":
      additionalFormFields = <ChangedAuthorityForm />;
      break;
    default:
      additionalFormFields = null;
  }

  return <>{additionalFormFields}</>;
};

export default AuthorizationManagementForm;
