import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSubscription } from "@apollo/client";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import { LanguageContext } from "../Language/Language";
import { FormattedMessage } from "react-intl";
import DisruptionAlert from "./DisruptionAlert";
import MobileNavBar from "./MobileNavBar";

import { DISRUPTION_STATUSES } from "../../queries";
import { useAccess } from "../../UserAccessContext";

const NavbarContainer = styled(Row)`
  width: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;

const UserImage = styled.img`
  max-height: 40px;
  border-radius: 50%;
`;

const MenuLanguageItem = styled.div`
  padding: 0;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MenuInfoItem = styled.div`
  color: rgba(255, 255, 255, 0.85);

  :hover {
    color: rgba(255, 255, 255, 0.85);
    cursor: default;
  }
`;

function useDisruptionStatus() {
  const { user } = useAuth0();
  const { data, loading } = useSubscription(DISRUPTION_STATUSES, {
    variables: { allowedStatuses: ["new", "open", "hold", "pending"] },
    skip: !user,
  });

  // Default to no value until the data has been fetched.
  let disruptionStatus = "";

  // Default to fine when data has been fetched.
  if (data !== undefined && !loading) {
    disruptionStatus = "fine";
  }

  try {
    const disruptionStatuses = data.raw_zendesk_ticket.map((disruption) => {
      const tags = disruption.ticket_to_tag_by_ticketid.map((tag) => tag.tag);

      // Has planned tag, force it to be a planned disruption.
      if (tags.includes("sys_opd_planned_disruption")) {
        return "sys_opd_planned_disruption";
      }

      return disruption.custom_status;
    });

    if (disruptionStatuses.includes("sys_opd_down")) {
      disruptionStatus = "down";
    } else if (disruptionStatuses.includes("sys_opd_partially_down")) {
      disruptionStatus = "partly";
    } else if (disruptionStatuses.includes("sys_opd_planned_disruption")) {
      disruptionStatus = "planned";
    } else if (disruptionStatuses.includes("sys_opd_maintenance")) {
      disruptionStatus = "maintenance";
    } else if (data !== undefined && disruptionStatuses.length > 0) {
      disruptionStatus = "partly";
    } else if (data !== undefined) {
      disruptionStatus = "fine";
    }
  } catch (exception) { }

  return disruptionStatus;
}

const NavBar = () => {
  const location = useLocation();
  const languageContext = useContext(LanguageContext);
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const { isInvoiceReader } = useAccess();
  const authMethod = isAuthenticated ? logout : loginWithRedirect;
  const authMessage = isAuthenticated ? (
    <FormattedMessage id="NavBar.logout" />
  ) : (
    <FormattedMessage id="NavBar.login" />
  );
  const homeLink = isAuthenticated ? {
    key: "/",
    label:
      <Link to="/">
        <FormattedMessage id="NavBar.home" />
      </Link>,
  } : null;
  const ticketsLink = isAuthenticated ? {
    key: "/tickets",
    label:
      <Link to="/tickets">
        <FormattedMessage id="NavBar.tickets" />
      </Link>,
  } : null;
  const servicesLink = isAuthenticated ? {
    key: "/disruptions",
    label:
      <Link to="/disruptions">
        <FormattedMessage id="NavBar.disruptions" />
      </Link>,
  } : null;
  const invoicesLink = isAuthenticated && isInvoiceReader ? {
    key: "/invoices",
    label:
      <Link to="/invoices">
        <FormattedMessage id="NavBar.invoices" />
      </Link>,
  } : null;
  const formsLink = isAuthenticated ? {
    key: "/new_ticket",
    label:
      <Link to="/new_ticket">
        <FormattedMessage id="NavBar.new_ticket" />
      </Link>,
  } : null;
  const disruptionStatus = useDisruptionStatus();
  const serviceAlert = isAuthenticated ? (
    <DisruptionAlert status={disruptionStatus} />
  ) : null;
  const serviceAlertItem = isAuthenticated ? {
    key: "disruption_alert",
    style: {
      backgroundColor: "transparent",
    },
    label: <DisruptionAlert status={disruptionStatus} />
  } : null;

  const changeToSwedish = () => {
    languageContext.setLanguage("sv");
  };

  const changeToEnglish = () => {
    languageContext.setLanguage("en");
  };

  const getLanguageColor = (language) => {
    return languageContext.language.locale === language
      ? "#fff"
      : "rgba(255, 255, 255, 0.65)";
  };

  const swedish = {
    key: "sv",
    label:
      <MenuLanguageItem onClick={() => changeToSwedish()}>
        sv
      </MenuLanguageItem>,
    style: {
      marginLeft: "10px",
      paddingRight: 0,
      color: getLanguageColor("sv"),
      backgroundColor: "transparent",
    }
  };

  const languageSeparator = {
    key: "language_separator",
    label: "/",
    style: {
      width: "5px",
      padding: 0,
      backgroundColor: "transparent",
    },
  };

  const english = {
    key: "en",
    label:
      <MenuLanguageItem onClick={() => changeToEnglish()}>
        en
      </MenuLanguageItem>,
    style: {
      marginRight: "10px",
      paddingLeft: 0,
      color: getLanguageColor("en"),
      backgroundColor: "transparent",
    }
  };

  let userPicture = null;
  let userName = null;
  let userEmail = null;

  if (user) {
    userPicture = user.picture;
    userName = user.name;
    userEmail = user.email;
  }

  const userDropdown = isAuthenticated ? {
    key: "user",
    style: {
      padding: 0,
    },
    label: <button
      className="ant-dropdown-link"
      onClick={(e) => e.preventDefault()}
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
      }}
    >
      <div style={{ maxHeight: "65px" }}>
        <UserImage src={userPicture} alt={userName} />
      </div>
    </button>,
    children: [
      {
        key: "user_name",
        label: <MenuInfoItem>
          <FormattedMessage id="NavBar.loggedin_as" /> {userName}
        </MenuInfoItem>,
        disabled: true,
        style: {
          cursor: "default",
        }
      },
      {
        key: "user_email",
        label: <MenuInfoItem>
          {userEmail}
        </MenuInfoItem>,
        disabled: true,
        style: {
          cursor: "default",
        }
      },
      {
        type: "divider",
      },
      {
        key: "logout",
        label: <span onClick={() => authMethod()}>
          {authMessage}
        </span>,
      }
    ],
  } : {
    key: "login",
    label: <span onClick={() => authMethod()}>
      {authMessage}
    </span>,
  };

  return (
    <>
      <NavbarContainer justify="space-between">
        <Col flex="auto">
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={[location.pathname]}
            items={[
              homeLink,
              ticketsLink,
              formsLink,
              servicesLink,
              invoicesLink
            ]}
            style={{ lineHeight: "64px", display: "flex", width: "100%" }}
          />
        </Col>
        <Col flex="210px">
          <Menu
            theme="dark"
            mode="horizontal"
            style={{
              lineHeight: "64px",
              display: "flex",
              width: "100%",
              paddingRight: "50px",
            }}
            items={[
              serviceAlertItem,
              swedish,
              languageSeparator,
              english,
              userDropdown,
            ]}
          />
        </Col>
      </NavbarContainer>
      <MobileNavBar
        authMessage={authMessage}
        ticketsLink={ticketsLink}
        servicesLink={servicesLink}
        invoicesLink={invoicesLink}
        formsLink={formsLink}
        changeToSwedish={changeToSwedish}
        changeToEnglish={changeToEnglish}
        serviceAlert={serviceAlert}
      />
    </>
  );
};

export default NavBar;
