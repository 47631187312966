import React from "react";
import { Tag } from "antd";

const color = {
  new: "blue",
  open: "green",
  pending: "orange",
  hold: "volcano",
  solved: "green",
  closed: "blue",
};

const Status = (props) => {
  const { status, text, minWidth } = props;

  if (minWidth) {
    return (
      <div style={{ minWidth, textAlign: "center" }}>
        <Tag style={{ margin: 0 }} color={color[status]}>
          {text}
        </Tag>
      </div>
    );
  } else {
    return <Tag color={color[status]}>{text}</Tag>;
  }
};

export default Status;
