import dayjs from "dayjs";

export const dateSorter = (ticketA, ticketB) => {
  const dateA = dayjs(ticketA.created_at);
  const dateB = dayjs(ticketB.created_at);
  return dateA.diff(dateB);
};

export const commentDateSorter = (commentA, commentB) => {
  const dateA = dayjs(commentA.created);
  const dateB = dayjs(commentB.created);
  return dateA.diff(dateB);
};

export const subjectSorter = (a, b) => a.subject.localeCompare(b.subject);

export const dateFormat = (date) => {
  return dayjs(date).format("YYYY-MM-DD, HH:mm");
};

export const dateFormatOnlyDate = (date) => {
  if (date == null) {
    return "";
  }
  return dayjs(date).format("YYYY-MM-DD");
};

export const getPageFromOffset = (offset) => {
  return offset / 10 + 1;
};

export const getOffsetFromPage = (page) => {
  return (page - 1) * 10;
};

export const getUserFieldFromTicket = (ticket, field, defaultValue = "") => {
  let value = defaultValue;

  try {
    if (ticket.ticket_cache_to_user_by_userid) {
      value = ticket.ticket_cache_to_user_by_userid[field];
    } else {
      value = ticket.ticket_to_user_by_userid[field];
    }
  } catch {}

  return value;
};

export const getUserFieldFromComment = (comment, field, defaultValue = "") => {
  let value = defaultValue;

  try {
    value = comment.comment_to_user_by_userid[field];
  } catch {}

  if (value === defaultValue) {
    try {
      value = comment.comment_cache_to_user_by_user_id[field];
    } catch {}
  }

  return value;
};

export const getCountFromTickets = (data) => {
  let count = 0;

  try {
    count = data.raw_zendesk_ticket_aggregate.aggregate.totalCount;
  } catch {}

  return count;
};

export const nameSorter = (a, b) => {
  getUserFieldFromTicket(a, "name").localeCompare(
    getUserFieldFromTicket(b, "name")
  );
};

export const parseTickets = (data) => {
  const tickets = [];

  if (data) {
    data.raw_zendesk_ticket.forEach((ticket) => {
      tickets.push({
        id: ticket.id,
        subject: ticket.subject,
        status: ticket.status,
        created_at: ticket.created_at,
        updated_at: ticket.updated_at,
        description: ticket.description,
        requester: getUserFieldFromTicket(ticket, "name"),
        email: getUserFieldFromTicket(ticket, "email"),
        comments: ticket.ticket_to_comment_cache_by_ticket_id.concat(
          ticket.ticket_to_comment_by_ticketid
        ),
      });
    });
  }

  return tickets;
};

export const parseDisruptions = (data) => {
  const disruptions = [];

  if (data) {
    data.raw_zendesk_ticket.forEach((disruption) => {
      const tags = disruption.ticket_to_tag_by_ticketid.map((tag) => tag.tag);
      const allowedStatuses = [
        "sys_opd_down",
        "sys_opd_maintenance",
        "sys_opd_partially_down",
        "sys_opd_planned_disruption",
      ];

      let customStatus = disruption.custom_status;
      let plannedDisruption = false;

      // Has planned tag, force it to be a planned disruption.
      if (tags.includes("sys_opd_planned_disruption")) {
        plannedDisruption = true;
      }

      // Fall back to partially down when we don't recognize the status.
      if (allowedStatuses.indexOf(customStatus) === -1) {
        customStatus = "sys_opd_partially_down";
      }

      disruptions.push({
        id: disruption.id,
        subject: disruption.subject,
        description: disruption.description,
        custom_status: customStatus,
        planned: plannedDisruption,
        created_at: disruption.created_at,
        updated_at: disruption.updated_at,
        replies: disruption.ticket_to_comment_by_ticketid,
      });
    });
  }

  return disruptions;
};
