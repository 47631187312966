import React, { useState, useEffect, useRef } from "react";
import { useSubscription } from "@apollo/client";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid } from "antd";

import {
  DISRUPTIONS_WITH_COMMENTS_SUBSCRIPTION,
  DISRUPTIONS_WITH_COMMENTS_COUNT,
} from "../../queries";
import Markdown from "../../Components/Markdown";
import { StyledTable } from "../Tickets";
import DisruptionReplies from "./DisruptionReplies";
import CustomEmpty from "../../Components/CustomEmpty";

import {
  parseDisruptions,
  dateFormat,
  getOffsetFromPage,
  getPageFromOffset,
  getCountFromTickets,
} from "../../tableMethods";

import {
  ExpandedInfo,
  ExpandedInfoHeaders,
  TicketContent,
} from "../Tickets/OpenTicketsTable";

import { userFeedbackNotification } from "../../Components/userFeedbackNotification";

const { useBreakpoint } = Grid;

const ClosedDisruptionsTable = (props) => {
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [sortDirection, setSortDirection] = useState("descend");
  const [sortField, setSortField] = useState("created_at");
  const tableData = useRef();
  const screens = useBreakpoint();

  let orderBy = {};
  orderBy[sortField] = sortDirection === "ascend" ? "asc" : "desc";

  const {
    data: countData,
    loading: countLoading,
    error: countError,
  } = useSubscription(DISRUPTIONS_WITH_COMMENTS_COUNT, {
    variables: {
      allowedStatuses: ["closed", "solved"],
    },
  });
  const { data, loading, disruptionsError } = useSubscription(
    DISRUPTIONS_WITH_COMMENTS_SUBSCRIPTION,
    {
      variables: {
        orderBy,
        allowedStatuses: ["closed", "solved"],
        limit: 10,
        offset: paginationOffset,
      },
    }
  );
  const closedDisruptions = parseDisruptions(data);

  if (!loading || tableData.current === undefined) {
    tableData.current = closedDisruptions;
  }

  const disruptionCount = getCountFromTickets(countData);

  useEffect(() => {
    if (disruptionsError || countError) {
      userFeedbackNotification(
        props.intl.formatMessage({ id: "Disruptions.fetchFailedError" }),
        "error"
      );
    }
    // eslint-disable-next-line
  }, [disruptionsError, countError]);

  const closedColumns = [
    {
      title: (
        <div>
          <FormattedMessage id="Disruptions.id" />
        </div>
      ),
      dataIndex: "id",
      width: "70px",
      sorter: true,
      sortOrder: sortField === "id" ? sortDirection : false,
      defaultSortOrder: "ascend",
      responsive: ["lg", "xl", "xxl"],
    },
    {
      title: (
        <div>
          <FormattedMessage id="Disruptions.subject" />
        </div>
      ),
      dataIndex: "subject",
      sorter: true,
      sortOrder: sortField === "subject" ? sortDirection : false,
    },
    {
      title: <FormattedMessage id="Disruptions.date.created" />,
      dataIndex: "created_at",
      sorter: true,
      sortOrder: sortField === "created_at" ? sortDirection : false,
      defaultSortOrder: "descend",
      render: dateFormat,
      width: "180px",
      responsive: ["md", "lg", "xl", "xxl"],
    },
    {
      title: <FormattedMessage id="Disruptions.date.solved" />,
      dataIndex: "updated_at",
      sorter: true,
      sortOrder: sortField === "updated_at" ? sortDirection : false,
      defaultSortOrder: "descend",
      render: dateFormat,
      width: "180px",
    },
  ];

  const onChange = (pagination, filters, sorter) => {
    setPaginationOffset(getOffsetFromPage(pagination.current));
    setSortDirection(sorter.order ? sorter.order : "ascend");
    setSortField(sorter.field);
  };

  return (
    <>
      <StyledTable
        loading={loading || countLoading}
        columns={closedColumns}
        dataSource={tableData.current}
        onChange={onChange}
        size="middle"
        locale={{
          emptyText: (
            <CustomEmpty descriptionId="Disruptions.closedEmptyText" />
          ),
        }}
        expandedRowRender={(record) => (
          <>
            <ExpandedInfo $visibleFromWidth={992}>
              {(screens.xs || screens.sm || screens.md) && !screens.lg ? (
                <div>
                  <ExpandedInfoHeaders>
                    <FormattedMessage id="Disruptions.id" />:
                  </ExpandedInfoHeaders>{" "}
                  {record.id}
                </div>
              ) : null}
              {(screens.xs || screens.sm) && !screens.md ? (
                <div>
                  <ExpandedInfoHeaders>
                    <FormattedMessage id="Disruptions.date.created" />:
                  </ExpandedInfoHeaders>{" "}
                  {dateFormat(record.created_at)}
                </div>
              ) : null}
            </ExpandedInfo>
            <TicketContent>
              <Markdown>{record.description}</Markdown>
            </TicketContent>
            <br />
            {record.replies && record.replies.length > 1 ? (
              <>
                <DisruptionReplies
                  replies={record.replies}
                  dateFormat={dateFormat}
                />
                <br />
              </>
            ) : null}
          </>
        )}
        pagination={{
          pageSize: 10,
          hideOnSinglePage: true,
          total: disruptionCount,
          current: getPageFromOffset(paginationOffset),
          showSizeChanger: false,
        }}
        rowKey="id"
        showExpandColumn={!screens.xs}
        expandRowByClick
      />
    </>
  );
};

export default injectIntl(ClosedDisruptionsTable);
