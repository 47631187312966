import React from "react";
import { FormattedMessage } from "react-intl";

import NewTicketForm from "./NewTicketForm";

const NewTicket = () => {
  return (
    <div>
      <h1 style={{ marginBottom: "20px" }}>
        <FormattedMessage id="NewTicketForm.title" />
      </h1>
      <NewTicketForm />
    </div>
  );
};

export default NewTicket;
