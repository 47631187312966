import React from "react";
import { FormattedMessage } from "react-intl";
import OpenDisruptionsTable from "./OpenDisruptionsTable";
import ClosedDisruptionsTable from "./ClosedDisruptionsTable";

const DisruptionsPage = (props) => {
  return (
    <>
      <h2 style={{ marginBottom: "10px" }}>
        <FormattedMessage id="Disruptions.title" />
      </h2>
      <OpenDisruptionsTable />

      <h2 style={{ marginBottom: "10px" }}>
        <FormattedMessage id="Disruptions.closedTitle" />
      </h2>
      <ClosedDisruptionsTable />
    </>
  );
};

export default DisruptionsPage;
