import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import ScreenSharingLink from "../../Components/ScreenSharingLink";

const Footer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (max-width: 767px) {
    height: 160px;
    flex-direction: column;
  }
`;

const FooterCopyright = styled.div`
  flex-grow: 0;
  text-align: center;

  @media (max-width: 767px) {
    flex-grow: 0;
  }
`;

const FooterEdgeLeft = styled.div`
  flex-grow: 2;
  flex-basis: 0;

  @media (max-width: 767px) {
    display: none;
  }
`;

const FooterEdgeRight = styled.div`
  flex-grow: 2;
  flex-basis: 0;
  text-align: right;
  padding-right: 50px;

  @media (max-width: 767px) {
    text-align: center;
    padding-right: 0px;
    flex-grow: 0;
  }
`;

const SlimFooter = ({ marginTop, linkColor }) => {
  const colorForLinks = linkColor ? linkColor : "#0B2962";
  return (
    <Footer
      style={{
        marginTop: marginTop ? marginTop : "50px",
      }}
    >
      <FooterEdgeLeft></FooterEdgeLeft>
      <FooterCopyright>
        ©{dayjs().year()} by{" "}
        <a href="https://cag.se" style={{ color: colorForLinks }}>
          CAG Datastöd
        </a>{" "}
        All Rights Reserved
      </FooterCopyright>
      <FooterEdgeRight>
        <ScreenSharingLink linkColor={colorForLinks} />
      </FooterEdgeRight>
    </Footer>
  );
};

export default SlimFooter;
