import { ApolloClient } from "@apollo/client/core";
import { InMemoryCache } from "@apollo/client/cache";
import { WebSocketLink } from "@apollo/client/link/ws";

const client = (graphql_origin, protocol, getToken) => {
  const WS_URL = `${protocol}://${graphql_origin}/v1/graphql`;

  return new ApolloClient({
    link: new WebSocketLink({
      uri: WS_URL,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          const bearerToken = await getToken();
          const authHeader = bearerToken ? `Bearer ${bearerToken}` : "";

          return {
            headers: {
              Authorization: authHeader,
            },
          };
        },
      },
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Subscription: {
          fields: {
            // Don't merge data because we only care about what the backend says.
            sp_backend_ticket_cache: { merge: false },
          },
        },
      },
    }),
  });
};

export default client;
