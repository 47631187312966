import { useQuery } from "@apollo/client";
import { GET_KNOWLEDGE_BASE_URL } from "../../../queries";

export default function useKnowledgeBaseUrl() {
  let url = null;
  const { data, loading } = useQuery(GET_KNOWLEDGE_BASE_URL);

  try {
    url = data.sheets_access_management[0].knowledge_base_url;
  } catch {}

  return { url, loading };
}
