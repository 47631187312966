import { FormattedMessage } from "react-intl";
import React from "react";
import { StyledTable } from "../Tickets/index";
import { dateSorter, dateFormat } from "../../tableMethods";
import styled from "styled-components";

const TitleRow = styled.div`
  @media (max-width: 767px) {
    margin-bottom: 8px;
    line-height: 20px;
  }
`;

const DateRow = styled.div`
  font-size: 12px;

  > h2 {
    display: inline-block;
    font-size: 12px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const InvoiceTable = (props) => {
  const { invoices, loading } = props;

  const invoiceColumns = [
    {
      title: <FormattedMessage id="Invoices.name" />,
      dataIndex: "name",
      render: (name, invoice) => {
        return (
          <div>
            <TitleRow>{name}</TitleRow>
            <DateRow>
              <h2>
                <FormattedMessage id="Invoices.created" />
                :&nbsp;
              </h2>
              {dateFormat(invoice.created_at)}
            </DateRow>
            <DateRow>
              <h2>
                <FormattedMessage id="Invoices.updated" />
                :&nbsp;
              </h2>
              {dateFormat(invoice.updated_at)}
            </DateRow>
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="Invoices.created" />,
      dataIndex: "created_at",
      render: dateFormat,
      width: "160px",
      responsive: ["md", "lg", "xl", "xxl"],
    },
    {
      title: <FormattedMessage id="Invoices.updated" />,
      dataIndex: "updated_at",
      sorter: dateSorter,
      defaultSortOrder: "descend",
      render: dateFormat,
      width: "160px",
      responsive: ["md", "lg", "xl", "xxl"],
    },
    {
      title: "",
      dataIndex: "action",
      align: "left",
      width: "0px",
    },
  ];

  return (
    <StyledTable
      loading={loading}
      columns={invoiceColumns}
      dataSource={invoices}
      pagination={{
        pageSize: 10,
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
      rowKey="id"
      size="middle"
    />
  );
};

export default InvoiceTable;
