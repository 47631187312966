import React from "react";
import { Empty } from "antd";
import { FormattedMessage } from "react-intl";

const CustomEmpty = (props) => {
  return (
    <Empty
      description={
        <span style={{ color: "rgba(0, 0, 0, 0.65)" }}>
          <FormattedMessage id={props.descriptionId} />
        </span>
      }
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  );
};

export default CustomEmpty;
