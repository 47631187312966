import React from "react";
import { Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

export const IconWrapper = styled.div`
  background-color: ${(props) =>
    (props.status === "fine" && "rgba(54,191,70,1)") ||
    (props.status === "down" && "rgba(255,0,0,1)") ||
    (props.status === "partly" && "rgba(255,165,0,1)") ||
    (props.status === "planned" && "rgba(0,151,255,1)") ||
    (props.status === "maintenance" && "rgba(0,151,255,1)")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 30px;
  border-radius: 5%;
  position: absolute;
  top: 15px;

  @media (max-width: 991px) {
    right: 100px;
  }
`;

const AlertIcon = styled(WarningOutlined)`
  font-size: 25px!important;
  color: white;
  margin-bottom: 1px;
`;

const DisruptionAlert = (props) => {
  const { status } = props;
  return (
    <>
      <Link to="/disruptions">
        <IconWrapper status={status}>
          <Tooltip
            placement="bottomRight"
            title={
              status && status.length > 0 ? (
                <FormattedMessage id={`Disruptions.status.${status}`} />
              ) : null
            }
          >
            <AlertIcon />
          </Tooltip>
        </IconWrapper>
      </Link>
    </>
  );
};

export default DisruptionAlert;
