import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import styled from "styled-components";

import Home from "./Pages/Home";
import Tickets from "./Pages/Tickets";
import Disruptions from "./Pages/Disruptions";
import Invoices from "./Pages/Invoices";
import NewTicket from "./Pages/NewTicket";
import SlimFooter from "./Components/Footer/SlimFooter";
import { useAccess } from "./UserAccessContext";
import Landingpage from "./Pages/Landingpage";
import DeprecationAlert from "./Components/DeprecationAlert";

const { Content } = Layout;

export const AppContent = styled(Content)`
  padding: 0 50px;
  min-height: calc(100vh - 194px);
  // max-height: auto;
  display: inline-block;

  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const PageContent = styled.div`
  background: #fff;
  padding: 24px;
  min-height: calc(100vh - 194px);

  @media (max-width: 991px) {
    padding: 15px;
  }

  ${(props) => (props.center ? `display: flex; justify-content: center` : "")}
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, isLoading } = useAuth0();
  let subComponents = null;

  if (isAuthenticated && !isLoading) {
    subComponents = children;
  }

  return (
    (isAuthenticated || isLoading) ?
      subComponents :
      <Navigate to="/" />
  );
}

function InvoiceReaderOnly({ children }) {
  const { isInvoiceReader, isInvoiceReaderLoading } = useAccess();
  const allowedToSeeInvoices =
    isInvoiceReader !== null && isInvoiceReader && !isInvoiceReaderLoading;

  // This is true before isInvoiceReader has been fetched. We do this in order
  // to not redirect before we have fetched isInvoiceReader.
  if (isInvoiceReader == null) {
    return null;
  }

  return allowedToSeeInvoices ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/",
        state: { from: "/invoices" },
      }}
    />
  );
}

const Router = () => {
  const { isAuthenticated } = useAuth0();
  const pages = [
    { path: "/tickets", component: <Tickets /> },
    { path: "/new_ticket", component: <NewTicket /> },
    { path: "/disruptions", component: <Disruptions /> },
    {
      path: "/invoices",
      component: (
        <InvoiceReaderOnly>
          <Invoices />
        </InvoiceReaderOnly>
      ),
    },
  ];

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* Home page */}
        <Route path="/" element={isAuthenticated ? <Home /> : <Landingpage />} />

        {/* Pages */}
        {pages.map((page) => (
          <Route path={page.path} key={page.path} element={(
            <PrivateRoute>
              <AppContent>
                <DeprecationAlert />
                <PageContent>{page.component}</PageContent>
              </AppContent>
              <SlimFooter />
            </PrivateRoute>
          )} />
        ))}

        {/* Redirect from old URL */}
        <Route path="/services" element={(
          <Navigate to="/disruptions" />
        )}>
        </Route>

        {/* Fallback route */}
        <Route path="*" element={(
          <Navigate to={{ pathname: "/" }} />
        )} />
      </Routes>
    </>
  );
};

export default Router;
