import React from "react";
import { Form } from "antd";
import { Input, DatePicker, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { useCustomFieldOptions } from "./NewTicketForm";

const { Option } = Select;

const NewEmployeeForm = (props) => {
  const { ticketType, ticketCategory } = props;
  const productOptions = useCustomFieldOptions(
    ticketType,
    ticketCategory,
    "products"
  );

  return (
    <>
      <Form.Item
        name="employee_name"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.employee_name_required_message" />
            ),
          },
        ]}
        label={<FormattedMessage id="NewTicketForm.employee_name" />}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="employee_start_date"
        label={<FormattedMessage id="NewTicketForm.employee_start_date" />}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.employee_start_date_required_message" />
            ),
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      {productOptions.length > 0 ? (
        <Form.Item
          name="products"
          label={<FormattedMessage id="NewTicketForm.employee_products" />}
        >
          <Select mode="multiple" showArrow>
            {productOptions.map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      ) : null}
      <Form.Item
        name="cellphone_number"
        label={<FormattedMessage id="NewTicketForm.cellphone_number" />}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.cellphone_number_required_message" />
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default NewEmployeeForm;
