import { useQuery } from "@apollo/client";
import { GET_LOGIC_MONITOR_URL } from "../../../queries";

export default function useLogicMonitorUrl() {
  let logicMonitorUrl = null;
  const { data, logicMonitorLoading } = useQuery(GET_LOGIC_MONITOR_URL);

  try {
    logicMonitorUrl = data.sheets_access_management[0].logic_monitor_url;
  } catch {}

  return { logicMonitorUrl, logicMonitorLoading };
}
