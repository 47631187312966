import React from "react";
import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";

const { Option } = Select;

const TicketTypeSelect = (props) => {
  const { handleTicketTypeChange, additionalForms } = props;
  let component = null;

  if (additionalForms.length > 0) {
    component = (
      <Form.Item
        name="ticket_type"
        rules={[{ required: true }]}
        label={<FormattedMessage id="NewTicketForm.ticket_type" />}
      >
        <Select onChange={handleTicketTypeChange}>
          <Option value="support">
            <FormattedMessage id={`NewTicketForm.ticket_type.support`} />
          </Option>
          {additionalForms.map((formName, index) => (
            <Option value={formName} key={index}>
              <FormattedMessage id={`NewTicketForm.ticket_type.${formName}`} />
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return component;
};

export default TicketTypeSelect;
