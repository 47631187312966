import React from "react";
import { Form } from "antd";
import { Input, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";

const ChangedAuthorityForm = (props) => {
  return (
    <>
      <Form.Item
        name="employee_name"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.employee_name_required_message" />
            ),
          },
        ]}
        label={<FormattedMessage id="NewTicketForm.employee_name" />}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="authorization_effective_date"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="NewTicketForm.authorization_effective_date_required_message" />
            ),
          },
        ]}
        label={
          <FormattedMessage id="NewTicketForm.authorization_effective_date" />
        }
      >
        <DatePicker />
      </Form.Item>
    </>
  );
};

export default ChangedAuthorityForm;
