/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import LandingpageContent from "./LandingpageContent";
import DeprecationAlert from "../../Components/DeprecationAlert";

import Footer from "../../Components/Footer/Footer";

import { Footer10DataSource } from "../../Components/Footer/data.source";
import "../../Components/Footer/less/antMotionStyle.less";

const Landingpage = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          minHeight: "calc(100vh - 482px)",
        }}
      >
        <DeprecationAlert />
        <LandingpageContent />
      </div>
      <Footer
        id="Footer1_0"
        dataSource={Footer10DataSource}
        style={{ minHeight: "416px" }}
      />
    </>
  );
};

export default Landingpage;
