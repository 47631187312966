import React from "react";

import NewEmployeeForm from "./NewEmployeeForm";
import EndEmploymentForm from "./EndEmploymentForm";

const OnOffboardingForm = (props) => {
  const { ticketType, ticketCategory } = props;
  let additionalFormFields = null;

  if (ticketCategory === "new_employee") {
    additionalFormFields = (
      <NewEmployeeForm
        ticketType={ticketType}
        ticketCategory={ticketCategory}
      />
    );
  } else if (ticketCategory === "end_employment") {
    additionalFormFields = <EndEmploymentForm />;
  }

  return <>{additionalFormFields}</>;
};

export default OnOffboardingForm;
